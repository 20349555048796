:root {
  --fs-sm: 12.8px;
}

.product-variants {
  font-size: var(--fs-sm);
}

.product-variants .product-variant-grid {
  display: grid;
  grid-template-columns: 1.5fr repeat(4, minmax(0, 1fr));
  gap: 15px;
}

.product-variants .product-variant-body {
  max-height: 310px;
  overflow-y: auto;
}

.product-variants .product-variant-body .grid-item {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-variants .product-variant-head {
  margin-bottom: 15px;
}

.product-variants .head-tag {
  font-size: 10px;
  font-weight: bold !important;
  background-color: var(--theme-body-bg-light-dark-color);
  color: var(--black-text-color);
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  display: block;
  white-space: nowrap;
  border: 0;
  text-align: center;
  text-transform: uppercase;
}

.product-variants .body-tag {
  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);
}

.product-variants .product-variant-group {
  background-color: var(--theme-body-bg-light-color);
}

.product-variants .product-variant-body .grid-item:nth-child(2) {
  padding-left: 12px;
  justify-content: flex-start;
}

.product-variants .product-variant-body .grid-item:last-child {
  padding-right: 12px;
  justify-content: flex-end;
}

.product-variants .product-variant-body .grid-item p {
  margin-bottom: 0;
}

.product-variants .product-variant-body .product-variant-action-btn {
  display: flex;
  gap: 10px;
}

.product-variants .product-variant-group:not(:last-child) {
  border-bottom: 5px solid var(--theme-body-bg-white-color);
}

.product-variants .product-info-box-small {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-variants .product-info-box-small .product-info-box-small-image {
  border: var(--border-light-color);
  background-color: var(--theme-body-bg-white-color);
  border-radius: 5px;
  overflow: hidden;
  width: 70px;
  min-width: 70px;
  min-height: 60px;
  display: grid;
  place-items: center;
}

.product-variants .product-info-box-small .product-info-box-small-image img {
  min-width: 65px;
  min-height: 45px;
  object-fit: contain;
  object-position: center;
}

.product-variants
  .product-info-box-small
  .product-info-box-small-image.default-img
  img {
  padding-left: 10px;
  padding-right: 10px;
}

.product-variants .product-info-box-small .product-info-box-small-text {
  font-size: var(--fs-sm);
  line-height: 1.6;
}

.product-variants .product-info-box-small .product-info-box-small-text b {
  font-weight: var(--fw-bold);
}

.product-variants .accordion .accordion-item {
  border: 0;
  padding: 0 12px !important;
  background-color: transparent !important;
}

.product-variants .accordion .accordion-body {
  border: 0;
  padding: 15px 0 !important;
  background-color: transparent !important;
  border-top: 3px solid var(--border-yellow-color) !important;
}

.product-variants .accordion .accordion-body h3 {
  font-size: 18px;
  margin-bottom: 15px !important;
}

.product-variants :is(.measurement-input, .select-wrapper) {
  height: 42px;
  background-color: var(--theme-body-bg-white-color);
}

.product-variants .form-control {
  font-size: var(--fs-sm);
  height: 100%;
}

.product-variants label {
  font-size: 12px;
  font-weight: var(--fw-bold) !important;
  margin-bottom: 5px !important;
  text-transform: uppercase;
}

.product-variants label a {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: all 0.15s ease-in-out;
}

.product-variants label a i {
  color: var(--yellow-text-color);
  font-size: 14px;
  margin-bottom: 1px;
}

.product-variants .sm-icon {
  transition: all 0.22s ease-in-out;
}

.product-variants .sm-icon.active {
  transform: rotate(-180deg);
}

@media (max-width: 991px) {
  .product-variants .product-variant-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }

  .product-variants .product-variant-body .product-variant-grid {
    gap: 0;
  }

  .product-variants .product-variant-body {
    max-height: 450px;
  }

  .product-variants .product-variant-body .grid-item {
    padding: 12px !important;
    border-bottom: 1px solid var(--border-light-color);
    justify-content: center !important;
  }

  .product-variants .product-variant-body .grid-item:nth-last-child(-n + 2) {
    border-bottom: 0 !important;
  }

  .product-variants .product-variant-head.desktop {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .product-variants .product-variant-head.mobile {
    display: none !important;
  }

  .product-variants .grid-item.mobile {
    display: none !important;
  }
}

.image-view-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
}

.image-view-fixed .image-view-box {
  border-radius: 15px;
  background-color: var(--theme-body-bg-white-color);
  display: grid;
  place-items: center;
  min-width: 500px;
  height: 400px;
  z-index: 1;
  position: relative;
}

.image-view-fixed .image-view-box img {
  height: 300px;
  object-fit: contain;
  object-position: center;
}

.image-view-fixed .image-view-box .sm-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  transform: translate(100%, -100%);
}

.image-view-fixed .image-view-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  .image-view-fixed .image-view-box {
    min-width: 100%;
  }

  .image-view-fixed .image-view-box .sm-icon {
    top: 10px;
    right: 10px;
    transform: unset;
  }
}
