.single-product :is(.product-image-main, .product-image-sub) {
  background-color: var(--theme-body-bg-light-dark-color);
  display: grid;
  place-items: center;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-light-color);
  height: var(--product-box-img-lg);
  position: relative;
  padding-bottom: 80%;
}

.single-product :is(.product-image-main, .product-image-sub) img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 60%;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: multiply;
  transform: translate(-50%, -50%);
}

.single-product :is(.product-image-sub) {
  border-radius: var(--border-radius-sm);
  background-color: var(--theme-body-bg-light-color);

}

.product-image-sub-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: var(--gap-15-25);
  gap: var(--gap-15-25);
}

.nav-pills .nav-link {
  padding: 0;
  outline: unset;
  box-shadow: unset;
  background-color: transparent!important;
}

.nav-pills .nav-link.active .product-image-sub {
  background-color: var(--theme-body-bg-light-dark-color);
}

hr {
  margin: var(--gap-15-25) 0;
}

.product-buy-button {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--gap-25);
}

@media (min-width: 476px) {
  .product-buy-button {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1400px) {
  hr {
    margin: 35px 0;
  }
}

/*  */

.single-product .icons-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: flex-start;
  gap: 15px;
}

.card-icon-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-icon-box .card-icon-box-img {
  background-color: var(--theme-body-bg-light-color);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding: 10px;
  display: grid;
  place-items: center;
  margin-bottom: 10px;
}

.card-icon-box .card-icon-box-img img {
  height: 45px;
  object-fit: contain;
  object-position: center;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.card-icon-box b {
  font-size: 12px;
  font-weight: var(--fw-bold);
  display: block;
  line-height: 1.2;
  max-width: 80%;
}

@media (min-width: 1200px) {
  .card-icon-box .card-icon-box-img {
    width: 80px;
    height: 80px;
  }

  .card-icon-box b {
    font-size: 16px;
  }
}

/* -------------------- Product More Infomation -------------------- */

.table-responsive + .table-responsive {
  margin-top: var(--gap-25);
}

.table :is(td, th) {
  font-size: 14px;
  padding: 10px;
  background-color: inherit !important;
  border-bottom: 0;
  vertical-align: middle;
}

.table tbody tr:nth-child(odd) {
  background-color: var(--theme-body-bg-light-color);
}

/* -------------------- Product Single Page -------------------- */

.single-product .product-image-container {
  position: relative;
}

.single-product .product-image-container .single-product-sku {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 99;
}

.single-product-sku span {
  background-color: var(--theme-body-bg-white-color);
  color: var(--black-text-color);
  font-size: 16px;
  font-weight: var(--fw-medium);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(26, 23, 27, 0.06);
}

.single-product .accordion-1 {
  padding: var(--gap-15-25) 0;
}

.single-product .accordion-1 .accordion-item {
  border-bottom: 0;
  padding-bottom: 0;
}

/* -------------------- additional option table -------------------- */

.table-additional :is(th, td) {
  background-color: inherit !important;
  font-size: 14px;
  padding: 10px;
  border: 0;
}

.table-additional b {
  font-weight: var(--fw-bold);
}

.table-additional tbody tr {
  background-color: var(--theme-body-bg-light-color);
  border-bottom: 5px solid var(--theme-body-bg-white-color);
}

.table-additional tbody tr:last-child {
  border-bottom: 0;
}

.table-additional thead tr > * {
  position: sticky !important;
  top: 0 !important;
  z-index: 2 !important;
  background-color: #fff !important;
}
.table-additional.left-sticky thead tr > *:nth-child(1) {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9 !important;
  background-color: #fff !important;
}

.table-additional.left-sticky tbody tr > *:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}

.table-additional.right-sticky thead tr > *:last-child {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 9 !important;
  background-color: #fff !important;
}

.table-additional.right-sticky tbody tr > *:last-child {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: #fff;
}

.table-additional :is(thead, tfoot) tr > * {
  padding: 0;
}

.table-additional :is(thead, tfoot) tr > * :is(span, button) {
  background-color: var(--theme-body-bg-light-dark-color);
  color: var(--black-text-color);
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  display: block;
  white-space: nowrap;
  margin-bottom: 10px;
  border: 0;
  text-align: center;
}

.table-additional :is(thead, tfoot) tr > *:not(:last-child) {
  padding-right: 10px;
}

.text-and-price-box p {
  font-size: 12.5px;
  margin-bottom: 2px;
  min-width: 110px;
}

.text-and-price-box a {
  font-size: 11px;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .table-additional-wrapper {
    max-height: 335px;
  }
}

.table-additional-wrapper * {
  font-size: 12px !important;
}

.measurement-input {
  display: flex;
  flex-direction: row;
  align-items: stretchs;
  border-radius: 6px;
  border: 1px solid var(--border-light-color);
  overflow: hidden!important;
}

.measurement-input input.form-control {
  background-color: #fff !important;
  min-width: 100px;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
  border: 0!important;
}

.measurement-input select.form-control {
  width: auto;
  padding-left: 5px;
  padding-right: 0;
  white-space: nowrap;
  background-color: var(--theme-body-bg-light-dark-color) !important;
  border: 0!important;
}

.measurement-input .select-wrapper {
  border: 0!important;
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
  background-color: var(--theme-body-bg-light-dark-color) !important;
}

.popup-model-img {
  background-color: var(--theme-body-bg-light-dark-color);
  min-height: 300px;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--border-light-color);
}

.popup-model-img img {
  padding: 15px;
  width: 250px;
  height: 250px;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: multiply;
}

.table-view {
  table-layout: fixed;
}

.table-view thead tr {
  background-color: var(--theme-body-bg-light-dark-color);
}

.table-view th {
  font-size: 12px !important;
  font-weight: var(--fw-bold);
  text-transform: uppercase;
}

.table-view td {
  font-size: 14px !important;
}

.table-view b {
  font-weight: var(--fw-bold);
}

.table-view p {
  margin-bottom: 0;
}

.table-view tr {
  background-color: inherit;
}

.table-view th[colspan="2"] {
  font-size: 16px;
  background-color: var(--theme-body-bg-light-dark-color) !important;
}

.table-view :is(ol, ul) {
  margin: 0;
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
}

.variant-details-modal .modal-header {
  padding: 12px 24px;
}

.variant-details-modal .modal-body {
  padding: 24px;
}
