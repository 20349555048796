/* -------------------- Card 1 -------------------- */
.product-card-1 {
  border: 1px solid var(--border-light-color) !important;
  border-radius: 4px !important;
  height: 100%;
  background-color: var(--theme-body-bg-light-color);
  transition: all 0.25s ease;
  overflow: hidden;
}

.product-card-1:hover {
  box-shadow: 0 3px 12px 0 rgb(0, 0, 0, 0.08);
  transform: scale(1.02);
}

.bg-dark .product-card-1:hover {
  box-shadow: 0 3px 8px 0 rgb(0, 0, 0, 0.08);
}

.product-card-1 .card-image {
  padding: 15px;
  display: flex;
  justify-content: center;
}

.product-card-1 .card-image img {
  width: 200px;
  height: 250px;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: multiply;
}

.product-card-1 .card-body {
  background-color: var(--theme-body-bg-white-color);
  color: var(--black-text-color);
  padding: 20px 15px;
}

.card-sm.product-card-1 {
  box-shadow: unset;
}

.card-sm.product-card-1 .card-image img {
  height: 200px;
}

/* -------------------- Card 2 -------------------- */
.product-card-2 {
  border: 0;
  height: 100%;
  background-color: transparent;
  color: var(--black-text-color);
}

.product-card-2 .card-image img {
  transition: all 0.22s ease;
}

.product-card-2:hover .card-image img {
  transform: scale(1.08);
}

.product-card-2 .card-image {
  background-color: var(--theme-body-bg-light-color);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--border-light-color);
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.product-card-2 .card-image img {
  width: 200px;
  height: 250px;
  padding: 10px 0;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: multiply;
}

.product-card-2 .card-body {
  padding: 0 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.product-card-2 .card-body > div {
  width: 100%;
}

/* -------------------- Card others -------------------- */

.card-image {
  position: relative;
}

.card .fav-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.fav-icon {
  color: var(--theme-body-bg-red-light-color);
  background-color: var(--theme-body-bg-white-color);
  font-size: 14px;
  padding: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  box-shadow: 0 4px 4px rgba(26, 23, 27, 0.06);
  cursor: pointer;
  position: relative;
}

.fav-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);
  transition: all 0.15s ease-in-out;
}

.fav-icon:not(:hover) svg + svg {
  opacity: 0;
}

.fav-icon.active {
  color: var(--yellow-text-color);
}

.card-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.card h3 {
  font-size: var(--fs-16);
  margin-bottom: 0;
}

.card p {
  font-size: var(--md-font);
  margin-bottom: 0;
}

.price {
  color: var(--yellow-text-color) !important;
  font-size: 14px;
  font-weight: var(--fw-bold);
  line-height: 1.4;
  min-width: 120px;
  white-space: nowrap;
}

.price-lg.warning {
  font-size: 20px !important;
}

.price-lg {
  color: var(--yellow-text-color) !important;
  font-size: 28px;
  font-weight: var(--fw-medium);
}

.card .price {
  min-width: 120px;
  text-align: right;
}

.variant-info-price {
  color: var(--theme-body-bg-red-color);
  font-weight: var(--fw-bold);
  text-decoration: underline !important;
}

.variant-info-price:hover {
  color: var(--theme-body-bg-red-dark-color);
}

@media (max-width: 992px) {
  .price-lg {
    font-size: 20px;
  }
}

/* -------------------- Team Card -------------------- */

.team-card {
  border: 1px solid var(--border-light-color);
  border-radius: 3px !important;
  box-shadow: 0 3px 8px 0 rgb(0, 0, 0, 0.05);
  transition: all 0.25s ease-in-out;
  overflow: hidden;
}

.team-card:hover {
  box-shadow: 0 3px 12px 0 rgb(0, 0, 0, 0.08);
  transform: scale(1.02);
}

.team-card .card-image {
  position: relative;
  border-bottom: 1px solid var(--border-light-color);
}

.team-card .card-image img {
  width: 100%;
}

.team-card .card-body {
  background-color: var(--theme-body-bg-white-color);
  padding: 15px 20px;
}

.team-card .card-body b {
  color: var(--yellow-text-color);
  font-size: 16px;
  font-weight: var(--fw-bold);
  text-transform: uppercase;
}

.team-card .card-body h3 {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 0;
}

.team-card .card-hover-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-body-bg-yellow-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 17px;
  transform: rotateY(90deg);
  transition: all 0.22s linear;
}

.team-card:hover .card-hover-content {
  transform: rotateY(0);
}

.team-card .highlight-top::before {
  background-color: var(--black-text-color);
}

.team-card .card-hover-content div span {
  font-size: 32px;
  font-weight: var(--fw-bold);
}

.team-card .card-hover-content div b {
  font-size: 18px;
  font-weight: var(--fw-bold);
  display: block;
}

.team-card .card-hover-content div p {
  max-width: 250px;
}

@media (max-width: 991px) {
  .team-card .card-body b {
    font-size: 14px;
  }

  .team-card .card-body h3 {
    font-size: 18px;
  }

  .team-card .card-hover-content div span {
    font-size: 26px;
  }
}

/* -------------------- Wishlist Card -------------------- */

.card4 {
  --card-image-width: 200px;
  --card-img-width: 150px;
  padding: var(--gap-25) var(--gap-15-25);
  border-color: var(--border-light-color);
  background-color: var(--theme-body-bg-light-color);
  border-radius: 15px;
}

.card4 .card-image {
  min-width: var(--card-image-width);
  min-height: var(--card-image-width);
  margin-right: var(--gap-15-25);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card4 .card-image:not(span) {
  background-color: var(--theme-body-bg-white-color);
  border: 1px solid var(--border-light-color);
}

.card4 .card-image img {
  width: var(--card-img-width);
  height: var(--card-img-width);
  object-fit: contain;
  object-position: center;
  mix-blend-mode: multiply;
}

.card4 .card-body {
  padding: 0;
  gap: var(--gap-25);
}

.card4 .card-body .grid-item:nth-child(1) {
  min-width: 350px;
}

.card4 .card-body .grid-item:nth-child(2) {
  min-width: 250px;
}

.card4 .price {
  color: var(--yellow-text-color) !important;
  font-size: 1.1rem;
  font-weight: var(--fw-bold);
  line-height: 1.4;
}

@media (min-width: 992px) {
  .card4 {
    --card-image-width: 250px;
    --card-img-width: 200px;
  }

  .card4 .card-body {
    padding: var(--gap-25) 0;
  }

  .card4 .card-body .grid-item:nth-child(1) {
    max-width: 450px;
  }

  .card4 .price {
    font-size: 1.4rem;
  }
}

@media (min-width: 1360px) {
  .card4 {
    --card-image-width: 350px;
    --card-img-width: 250px;
  }
}

/* -------------------- Cards - profile color full -------------------- */

.card.card-img-holder .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.card.card-img-holder {
  box-shadow: 3px 3px 12px rgb(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .card.card-img-holder h2 {
    font-size: 28px;
  }

  .card.card-img-holder h2.h6 {
    font-size: 22px;
  }
}

/* -------------------- Your Order Card -------------------- */

.order-grid {
  display: flex;
  flex-direction: column;
  margin: -25px 0;
}

.order-card {
  border: 1px solid var(--border-light-color);
  background-color: var(--theme-body-bg-white-color) !important;
  border-radius: 5px;
  overflow: hidden;
}

.order-card h3 {
  font-size: 22px;
}

.order-card h4 {
  font-size: 18px;
  margin-bottom: 15px;
}

.order-card .order-card-label {
  font-size: 16px;
  font-weight: var(--fw-bold);
}

.order-card :is(.order-card-header, .order-card-footer) {
  background-color: var(--theme-body-bg-light-color);
  border-bottom: 1px solid var(--border-light-color);
  display: flex;
  justify-content: space-between;
}

.order-card .order-card-footer {
  border-top: 1px solid var(--border-light-color);
  border-bottom: 0;
}

.order-card .order-card-title {
  font-size: 16px;
  font-weight: var(--fw-bold);
  padding: 15px 30px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.order-card .label-1 {
  font-size: 18px;
}

.order-card ul.order-card-title {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.order-card ul.order-card-title li {
  border-left: 1px solid var(--border-light-color);
  padding: 15px 30px;
}

.order-card ul.order-card-title button {
  background-color: transparent !important;
  border: 0;
  padding: 0;
  color: var(--link-text-color);
}

.order-card .order-card-date {
  background-color: var(--theme-body-bg-light-dark-color);
  color: var(--black-text-color);
  border-radius: 4px;
  font-size: 12.5px !important;
  font-weight: var(--fw-bold);
  padding: 2px 5px;
  display: inline-block;
}

.order-card .order-card-body {
  padding: var(--gap-25) var(--gap-15-25);
}

.order-card .order-card-body > .row {
  row-gap: var(--gap-25);
}

.order-product-box {
  display: flex;
  gap: 15px;
}

.order-product-box .order-product-box-content {
  max-width: 230px;
  line-height: 1.4;
}

.order-product-box .order-product-box-content p {
  margin-bottom: 5px;
}

.order-product-box .order-product-box-image {
  background-color: var(--theme-body-bg-light-color);
  border: 1px solid var(--border-light-color);
  border-radius: 5px;
  padding: 5px;
  display: grid;
  place-items: center;
}

.order-product-box .order-product-box-image img {
  width: 75px;
  max-width: 75px;
  height: 75px;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: multiply;
}

.order-card address {
  max-width: 200px;
}

.order-focus {
  padding: 25px var(--container-gap);
}

.order-focus.active {
  background-color: #eaeaea;
}

@media (max-width: 991px) {
  .order-card .order-card-body .col-lg-4:nth-child(2) {
    border: 1px solid var(--border-white-color);
    border-width: 1px 0;
  }
}

/* -------------------- Tracker Line -------------------- */

.order-tracking-belt {
  display: flex;
  flex-direction: column;
  position: relative;
}

.order-tracking-belt .order-tracking-belt-item {
  font-size: 12px;
  font-weight: var(--fw-medium);
  min-height: 100px;
  display: flex;
  column-gap: 15px;
  position: relative;
}

.order-tracking-belt .order-tracking-belt-item::before {
  content: "";
  position: absolute;
  top: 1px;
  left: calc(15px - 2.5px);
  width: 5px;
  height: 100%;
  background-color: var(--theme-body-bg-light-dark-color);
}

.order-tracking-belt .order-tracking-belt-item.active::before {
  background-color: var(--theme-body-bg-yellow-color);
}

.order-tracking-belt .order-tracking-belt-item .order-tracking-belt-dot {
  font-size: 14px;
  background-color: var(--theme-body-bg-light-dark-color);
  color: var(--black-text-color);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: relative;
  z-index: 99;
}

.order-tracking-belt .order-tracking-belt-item .order-tracking-belt-dot.active {
  background-color: var(--theme-body-bg-yellow-color);
  color: var(--black-text-color);
  display: none;
}

.order-tracking-belt
  .order-tracking-belt-item.active
  .order-tracking-belt-dot.active {
  display: grid;
}

.order-tracking-belt
  .order-tracking-belt-item.active
  .order-tracking-belt-dot:not(.active) {
  display: none;
}

.order-tracking-belt .order-tracking-belt-item div {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.order-tracking-belt .order-tracking-belt-item div a {
  color: var(--yellow-text-color);
  font-weight: var(--fw-bold);
}

.order-tracking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--theme-body-bg-yellow-color);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.order-tracking-header h2 {
  font-size: 16px;
  font-weight: var(--fw-medium) !important;
}


/* -------------------- Tracking Box -------------------- */
.tracking-details-grid {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.tracking-details-box b {
  font-size: 14px;
  font-weight: var(--fw-bold);
  display: block;
  margin-bottom: 20px;
}

.tracking-details-box .content {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
}

.tracking-details-box .content:not(:last-child) {
  margin-bottom: 12px;
}

.tracking-details-box .content > span {
  width: 80px;
  min-width: 80px;
}

.tracking-details-box .content > div {
  border-left: 1px solid var(--border-dark-color);
  margin-left: 15px;
  padding-left: 15px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tracking-details-box .content > div p {
  margin-bottom: 0;
}

/* -------------------- production cards -------------------- */

.production-card {
  border: 0;
  background-color: transparent;
}

.production-card .card-image {
  border-radius: 10px;
  padding-bottom: 60%;
  position: relative;
  background-color: var(--theme-body-bg-light-color);
  border: 1px solid var(--border-light-color);
  overflow: hidden;
  margin-bottom: 15px;
}

.production-card .card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.22s ease;
}

.production-card .card-image:hover img {
  transform: scale(1.05);
}

.production-card h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

/* -------------------- Wishlist Card -------------------- */

.product-item {
  background-color: var(--theme-body-bg-light-color);
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
}

.product-item .product-item-content {
  display: flex;
  gap: 15px;
  font-size: 14px;
}

.product-item .product-item-content b {
  font-size: 16px;
  font-weight: var(--fw-bold);
}

.product-item .product-item-content .product-item-img {
  min-width: 90px;
  height: 70px;
  background-color: var(--theme-body-bg-white-color);
  border-radius: 5px;
  display: grid;
  place-items: center;
}

.product-item .product-item-content .product-item-img img {
  height: 60px;
  min-height: 60px;
  width: 60px;
  min-width: 60px;
  object-fit: contain;
  object-position: center;
}

/* -------------------- user profile pic -------------------- */

.user-profile-bio {
  display: flex;
  gap: var(--gap-15-25);
  row-gap: 10px;
  align-items: center;
}

.user-profile-bio .user-text {
  font-size: 16px;
  font-weight: var(--fw-medium) !important;
  /* text-align: center; */
  line-height: 1.4;
}

.user-profile-bio .user-text p {
  margin-bottom: 0;
}

.user-profile-bio .user-img {
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 6px;
  overflow: hidden;
  /* margin: 0 auto; */
  background-color: var(--theme-body-bg-light-color);
}

.user-profile-bio .user-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.form-box.sm .user-profile-bio {
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.user-profile-bio .user-btn {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .user-profile-bio {
    flex-direction: column !important;
    align-items: flex-start;
  }

  .user-profile-bio .user-btn {
    gap: 10px;
  }
}

.cart-sm-box .cart-sm-box-title {
  font-weight: var(--fw-bold);
  display: block;
  margin-bottom: 5px;
}

.cart-sm-box .cart-sm-box-content b {
  font-weight: var(--fw-bold);
  display: inline-block;
}

.cart-sm-box .cart-sm-box-content {
  min-width: 170px;
  max-width: 170px;
  white-space: pre-wrap;
  line-height: 1.2;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.cart-sm-box .cart-sm-box-content p {
  margin-bottom: 5px !important;
}

.cart-sm-box-img {
  background-color: var(--theme-body-bg-light-dark-color);
  border: 1px solid var(--border-light-color);
  border-radius: 5px;
  overflow: hidden;
  width: 70px;
  min-width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-sm-box-img img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  mix-blend-mode: multiply;
  padding: 10px;
}

/* -------------------- product-variant-table -------------------- */

.product-variant-table-container {
  max-height: 415px;
  overflow-y: auto; /* Enable vertical scrolling */
}

.product-variant-table-container2 {
  max-height: calc(
    356px - 55px
  ); /* Adjusted height to accommodate child content */
  overflow-y: visible;
}

.product-variant-table-container2.hide-scroll::-webkit-scrollbar {
  width: 0;
}

.product-variant-table-container2.hide-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.product-variant-table {
  table-layout: fixed;
}

.product-variant-table td {
  height: 70px;
}

.product-variant-table th,
.product-variant-table td,
.product-variant-table .form-control {
  font-size: 12px !important;
}

.product-variant-table > tbody > tr > td {
  padding: 0 !important;
}

.product-variant-table > thead th {
  padding: 0 7.5px 15px !important;
}

.product-variant-table > tfoot th {
  padding: 15px 7.5px 0 !important;
}

.product-variant-table .product-variant-child-table > :is(thead, tfoot) th {
  background-color: var(--theme-body-bg-light-color) !important;
}

.product-variant-table :is(thead, tfoot) th.cart-total span {
  background-color: var(--theme-body-bg-yellow-color) !important;
}

.product-variant-table
  .product-variant-child-table
  > :is(thead, tfoot)
  th
  > span {
  background-color: var(--theme-body-bg-white-color) !important;
}

.product-variant-table > :is(thead, tfoot) th:first-child {
  padding-left: 0 !important;
}

.product-variant-table > :is(thead, tfoot) th:last-child {
  padding-right: 0 !important;
}

.product-variant-table .product-variant-child-table :is(thead, tfoot) th {
  padding: 7.5px !important;
}

.product-variant-table
  .product-variant-child-table
  :is(thead, tbody)
  > tr
  :is(th, td):first-child {
  padding-left: 15px !important;
}

.product-variant-table
  .product-variant-child-table
  :is(thead, tbody)
  > tr
  :is(th, td):last-child {
  padding-right: 15px !important;
}

.product-variant-table :is(thead, tfoot) th > span {
  background-color: var(--theme-body-bg-light-dark-color);
  color: var(--black-text-color);
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  display: block;
  white-space: nowrap;
  border: 0;
  text-align: center;
}

.product-variant-table th,
.product-variant-table td {
  width: 50%;
  padding: 10px 7.5px;
  white-space: nowrap;
}

.product-variant-table .fix-input-width {
  min-width: 160px;
}

.product-variant-table :is(.select-wrapper, .form-control) {
  background-color: var(--theme-body-bg-white-color) !important;
  border-radius: 5px !important;
}

.product-variant-table
  tr
  td:last-child
  .product-variant-child-table
  tr
  th:last-child,
.product-variant-table
  tr
  td:last-child
  .product-variant-child-table
  tr
  td:last-child {
  position: sticky;
  right: 0;
  z-index: 99;
  background-color: var(--theme-body-bg-light-color) !important;
}

.product-variant-table > thead > tr {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: var(--theme-body-bg-white-color) !important;
}

.product-variant-child-table tbody tr {
  border-top: 3px solid var(--theme-body-bg-white-color);
}

.product-variant-table tbody :is(td, th) {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.product-variant-table :is(ol, ul) {
  padding-left: 20px;
  white-space: nowrap;
  margin: 0;
  line-height: 1.3;
}

.product-variant-table b {
  font-weight: var(--fw-bold);
}

.more-text {
  margin-left: 20px;
  height: 0;
  color: var(--yellow-text-color);
  font-weight: var(--fw-bold);
  letter-spacing: 0.3px;
  display: inline-block;
  line-height: 1.3;
}

.product-variant-table .scrollbar-sm::-webkit-scrollbar {
  position: fixed;
  top: 0;
  left: 0;
}

.n_a {
  font-size: 14px;
  font-weight: var(--fw-bold);
  display: block;
  text-align: center;
}

.user-selcted-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.user-selcted-list li {
  font-size: 12.8px;
  font-weight: var(--fw-bold);
  padding: 10px 25px;
  background-color: var(--theme-body-bg-white-color);
  display: inline-block;
  border-radius: 35px;
}


/* -------------------- tracking popup -------------------- */

.tracking-id {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
  gap: 5px;
}

.tracking-id span {
  background-color: var(--theme-body-bg-light-dark-color);
  border-radius: 3px;
  display: inline-block;
  font-size: 12.5px;
  font-weight: var(--fw-bold);
  padding: 2px 5px;
}

.tracking-details-modal .modal-title {
  font-size: var(--heading-3);
}

.tracking-details-modal .modal-header {
  align-items: flex-start;
}

.data-loader {
  border-color: var(--theme-body-bg-yellow-color);
  border-width: 0 3px;
}