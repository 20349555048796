@import "./meta.css";
@import "./header-footer.css";
@import "./swiper.css";
@import "./card.css";

/* -------------------- left right background shadow  -------------------- */
.bg-shadow-lr {
  position: relative;
}

.bg-shadow-lr::after,
.bg-shadow-lr::before {
  content: "";
  position: absolute;
  top: 0;
  right: calc(var(--container-gap) - (var(--container-gap) * 2));
  height: 100%;
  width: var(--container-gap);
  z-index: 99;
}

.bg-shadow-lr::before {
  left: calc(var(--container-gap) - (var(--container-gap) * 2));
}

.bg-shadow-white::after {
  background: linear-gradient(
    to left,
    rgba(255 255 255 / 100%) 0%,
    rgba(255 255 255 / 70%) 40%,
    rgba(255 255 255 / 45%) 60%,
    rgba(255 255 255 / 0%) 100%
  );
}

.bg-shadow-white::before {
  background: linear-gradient(
    to right,
    rgba(255 255 255 / 100%) 0%,
    rgba(255 255 255 / 70%) 40%,
    rgba(255 255 255 / 45%) 60%,
    rgba(255 255 255 / 0%) 100%
  );
}

.bg-shadow-dark::after {
  background: linear-gradient(
    to left,
    rgba(26 23 27 / 100%) 0%,
    rgba(26 23 27 / 70%) 40%,
    rgba(26 23 27 / 45%) 60%,
    rgba(26 23 27 / 0%) 100%
  );
}

.bg-shadow-dark::before {
  background: linear-gradient(
    to right,
    rgba(26 23 27 / 100%) 0%,
    rgba(26 23 27 / 70%) 40%,
    rgba(26 23 27 / 45%) 60%,
    rgba(26 23 27 / 0%) 100%
  );
}

/* -------------------- Main Banner  -------------------- */

.hero-banner-1 {
  position: relative;
  background-color: rgb(255, 204, 0, 0.08);
  color: var(--white-text-color);
  overflow: hidden;
}

.hero-banner-1 .banner-img {
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.hero-banner-1 .banner-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(22, 33, 58);
  background: linear-gradient(
    90deg,
    rgba(26 23 27 / 70%) 0%,
    rgba(26 23 27 / 30%) 60%,
    rgba(26 23 27 / 10%) 75%,
    rgba(70 70 70 / 0%) 100%
  );
}

.hero-banner-1 .banner-content {
  max-width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: var(--container-gap);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.hero-banner-1 .banner-content .banner-heading {
  font-size: 1.8rem;
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: 900 !important;
}

.hero-banner-1 .fade-up {
  overflow: hidden;
}

.hero-banner-1 .fade-up .banner-heading {
  transform: translateY(100px);
  opacity: 0;
}

.hero-banner-1.active .fade-up .banner-heading {
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55),
    opacity 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transform: translate(0);
  opacity: 1;
}

.hero-banner-2 {
  color: var(--black-text-color);
}

.hero-banner-2 .banner-img::before {
  display: none !important;
}

@media (min-width: 768px) {
  .hero-banner-1 .banner-content {
    max-width: 500px;
  }

  .hero-banner-1 .banner-img {
    background-position: 50% 50%;
    height: auto;
    padding-bottom: 36.7845%;
  }

  .hero-banner-1 .banner-img::before {
    background: linear-gradient(
      90deg,
      rgba(26 23 27 / 70%) 0%,
      rgba(26 23 27 / 45%) 30%,
      rgba(26 23 27 / 15%) 55%,
      rgba(26 23 27 / 10%) 75%,
      rgba(70 70 70 / 0%) 100%
    );
  }

  .hero-banner-1 .banner-content .banner-heading {
    font-size: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .hero-banner-1 .banner-content .banner-heading {
    font-size: 3.5rem;
  }
}

/* -------------------- about full image  -------------------- */
.image-container {
  border: 1px solid var(--border-light-color);
  border-width: 1px 0;
}

/* -------------------- Product Category  -------------------- */

.card-category {
  --card-category: 180px;
  text-align: center;
}

.card-category .card-image {
  width: var(--card-category);
  height: var(--card-category);
  background-color: var(--theme-body-bg-light-color);
  border: 1px solid var(--border-light-color);
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.card-category .card-image img {
  width: calc(var(--card-category) - var(--gap-15-25));
  height: calc(var(--card-category) - var(--gap-15-25));
  object-fit: contain;
  object-position: center;
  mix-blend-mode: multiply;
  padding: 15px;
}

.card-category .card-content {
  font-size: 17px;
  font-weight: var(--fw-medium);
  line-height: 1.4;
  width: var(--card-category);
  padding: 10px 15px 0 !important;
}

.card-category-wrapper,
.card-category {
  transition: all 0.22s ease-in-out;
}

.product-category-swiper
  .swiper-wrapper:hover
  .card-category-wrapper:not(:hover)
  .card-category,
.product-category-swiper.active-categories
  .swiper-wrapper:not(:hover)
  .card-category-wrapper:not(.active)
  .card-category {
  opacity: 0.4;
}

/* -------------------- Inner Page Intro -------------------- */

.inner-page-intro {
  display: grid;
  grid-template-columns: 2fr 4fr;
  row-gap: 20px;
}

.inner-page-intro br {
  display: none !important;
}

.inner-page-intro .intro-heading {
  font-size: 1rem;
  font-weight: 900 !important;
  line-height: 1;
  max-width: 250px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.inner-page-intro .grid-item > p:first-child {
  font-size: 22px;
  font-weight: var(--fw-medium);
  line-height: 1.4;
  margin-bottom: var(--gap-10-15);
}

.inner-page-intro div > p {
  font-size: 19px;
}

@media (max-width: 768px) {
  .inner-page-intro {
    grid-template-columns: 1fr;
  }

  .inner-page-intro .grid-item > p:first-child {
    font-size: 20px;
  }
}

/* -------------------- About us section 2 -------------------- */
.about_img_grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: var(--section-padding);
}

.about_img_grid .grid-item {
  background-color: var(--theme-body-bg-light-color);
}

.about_img_grid .grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 767px) {
  .about_img_grid {
    display: block;
    padding: 0 var(--container-gap);
  }

  .about_img_grid .grid-item:not(:nth-child(2)) {
    display: none !important;
  }
}

/* -------------------- User Wishlist -------------------- */

.tag {
  background-color: var(--theme-body-bg-light-dark-color);
  color: var(--black-text-color);
  border-radius: 3px;
  font-size: 12.5px;
  font-weight: var(--fw-bold);
  padding: 2px 5px;
  display: inline-block;
  margin-bottom: 5px;
}

.wishlist-table {
  width: 100%;
}

.wishlist-table tr td {
  padding: 0 0 30px;
}

.wishlist-table tbody tr:last-child td {
  padding-bottom: 0 !important;
}

[aria-labelledby="modal-for-error"] .modal-body {
  text-align: center;
  padding: 40px 15px;
  position: relative;
}

[aria-labelledby="modal-for-error"] .modal-content {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 10px !important;
  overflow: hidden;
}

[aria-labelledby="modal-for-error"] .modal-footer {
  justify-content: center;
}

.input-icon-group {
  position: relative;
}

.input-icon-group .input-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  min-width: 36px;
  height: 25px;
  min-height: 36px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

small.error {
  font-size: 14px;
  color: var(--theme-body-bg-red-color);
  font-weight: var(--fw-bold);
  display: block;
  margin: 5px 0;
  text-transform: capitalize;
}

small.success {
  font-size: 14px;
  color: var(--theme-body-bg-green-color);
  font-weight: var(--fw-bold);
  display: block;
  margin: 5px 0;
  text-transform: capitalize;
}

input[disabled] + .input-icon {
  opacity: 0.2;
  pointer-events: none;
}

.btn-copy {
  display: inline-block;
  border: 0;
  background-color: transparent;
  padding: 0;
}

.wishlist-grid {
  display: grid;
  gap: 24px;
}

@media (min-width: 768px) {
  .wishlist-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 992px) {
  .wishlist-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1200px) {
  .wishlist-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1800px) {
  .wishlist-grid {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

/* -------------------- Add To Cart Page - User -------------------- */

.user-cart-page .cart-heading {
  border-bottom: 2px solid var(--theme-body-bg-yellow-color);
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.user-cart-page .cart-heading-bottom {
  border-top: 2px solid var(--theme-body-bg-yellow-color);
  padding-top: 25px;
  margin-top: 25px;
}

.user-cart-page .user-cart-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: var(--gap-25-50);
}

.cart-box-info-1 {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cart-box-info-1 .cart-image {
  min-width: 80px;
  min-height: 80px;
  border-radius: 5px;
  background-color: var(--theme-body-bg-white-color);
  padding: 10px;
  display: grid;
  place-items: center;
}

.cart-box-info-1 .cart-content {
  max-width: 300px;
  min-width: 300px;
}

.cart-box-info-1 .cart-content > * {
  font-size: 16px;
  font-weight: var(--fw-bold);
  margin-bottom: 0 !important;
}

.table {
  margin-bottom: 0;
}

.white-space {
  white-space: nowrap;
}

.table .table-heading {
  font-size: 14px;
  background-color: var(--theme-body-bg-light-dark-color);
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  display: block;
  white-space: nowrap;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.user-cart-page-table > :not(caption) > * > * {
  background-color: transparent;
}

.user-cart-page-table thead tr {
  background-color: transparent !important;
  border-bottom: 0 !important;
}

.user-cart-page-table thead tr th {
  border-bottom: 0 !important;
  padding: 0;
  text-align: center;
  vertical-align: center;
}

.user-cart-page-table thead tr th:not(:last-child) {
  padding-right: 15px;
}

.user-cart-page-table tbody td {
  font-size: 16px;
  font-weight: var(--fw-medium);
  padding: 15px;
  vertical-align: middle;
}

.user-cart-page-table tbody tr:not(:last-child) {
  border-bottom: 5px solid var(--theme-body-bg-white-color);
}

.user-cart-page-table tbody tr:last-child,
.user-cart-page-table tbody tr:last-child td {
  border-bottom: 0 !important;
}

.user-cart-page-table tbody tr td {
  background-color: var(--theme-body-bg-light-color) !important;
}

.user-cart-page-table tbody tr td:not(:first-child) {
  text-align: center;
}

.user-cart-page-table :is(th, td).center {
  text-align: center;
  vertical-align: center;
}

@media (max-width: 991px) {
  .user-cart-page .user-cart-grid {
    display: flex;
    flex-direction: column;
  }

  .cart-box-info-1 .cart-content {
    max-width: 200px;
    min-width: 200px;
  }
}

/* -------------------- Summary Table -------------------- */
.table.summary-table :is(th, td) {
  font-size: 16px;
  font-weight: var(--fw-medium);
  padding: 10px 15px !important;
  vertical-align: middle !important;
  border-bottom: 0 !important;
  background-color: inherit !important;
}

.table.summary-table th {
  font-weight: var(--fw-bold) !important;
}

.table.summary-table tfoot :is(th, td) {
  font-size: 17px;
  font-weight: var(--fw-bold) !important;
}

.table.summary-table tr :is(td, th):last-child {
  text-align: right !important;
}

.table.summary-table tbody tr {
  background-color: var(--theme-body-bg-light-color) !important;
  border-bottom: 5px solid var(--theme-body-bg-white-color) !important;
}

.table.summary-table tfoot tr:last-child {
  border-bottom: 0 !important;
  background-color: var(--theme-body-bg-light-dark-color) !important;
}

/* -------------------- User Auth -------------------- */

.form-page-wrapper {
  padding: var(--section-padding) var(--gap-10-15);
  background-color: var(--theme-body-bg-light-color);
  min-height: calc(100vh - var(--header-height));
  margin-bottom: var(--gap-25-50);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-page-wrapper > .row {
  width: 100%;
}

.form-page-wrapper .user-bio {
  margin-bottom: 25px;
}

.form-box {
  background-color: var(--theme-body-bg-light-color);
  padding: var(--gap-25-50) var(--gap-15-25);
  border-radius: var(--border-radius-sm) !important;
}

.form-box-icon {
  width: 80px;
  height: 80px;
  border: 2px solid var(--theme-body-bg-light-dark-color);
  font-size: 35px;
  color: var(--theme-body-bg-light-dark-color);
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.form-box-icon .spinner-border {
  width: 80px;
  height: 80px;
  border-width: 3px;
  border-right: 0;
}

.form-box-icon.unset {
  border: 0;
}

.form-box_error .form-box-icon {
  border-color: var(--theme-body-bg-red-color);
  color: var(--theme-body-bg-red-color);
}

.form-box_success .form-box-icon {
  border-color: var(--theme-body-bg-green-color);
  color: var(--theme-body-bg-green-color);
}

.form-box_warning .form-box-icon {
  border-color: var(--theme-body-bg-yellow-color);
  color: var(--theme-body-bg-yellow-color);
}

.form-page-wrapper .form-box {
  background-color: var(--theme-body-bg-white-color);
}

.form-box + .form-box {
  margin-top: 25px;
}

.form-box.sm {
  padding: 25px 15px !important;
}

.form-box.md {
  padding: 50px var(--gap-25-50) !important;
}

label {
  font-size: 14px;
  font-weight: var(--fw-medium);
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}

label > sup {
  color: var(--theme-body-bg-red-color);
  font-size: 20px;
  top: -0.2em;
}

.form-group {
  margin-bottom: 20px;
}

.form-group + .gap-3 {
  margin-top: 15px;
}

.form-control {
  background-color: var(--theme-body-bg-light-color);
  border: 1px solid var(--border-light-color) !important;
  padding: 10px 15px;
  border-radius: 5px;
  appearance: inherit !important;
}

.error-message {
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
  color: var(--theme-body-bg-red-color);
}

[aria-invalid="true"] {
  border-color: var(--theme-body-bg-red-color) !important;
}

select.form-control {
  appearance: auto !important;
}

.form-control:focus {
  background-color: var(--theme-body-bg-light-dark-color);
  border: 1px solid var(--border-light-color) !important;
  box-shadow: unset;
}

.MuiFormControl-root {
  width: 100% !important;
  height: 46px !important;
  margin: 0 !important;
}

.MuiFormControl-root > div {
  height: 100% !important;
}

.form-control-mui #demo-multiple-checkbox {
  background-color: var(--theme-body-bg-light-color);
  border-radius: 5px !important;
  padding: 10px 15px !important;
}

.form-control-mui .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--border-light-color) !important;
}

.MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  padding: 10px 15px !important;
  background-color: transparent !important;
}

.select-wrapper {
  background-color: var(--theme-body-bg-light-color);
  border: 1px solid var(--border-light-color) !important;
  border-radius: 5px !important;
  padding-right: 6px;
}

.select-wrapper .form-control {
  background-color: transparent !important;
  border: 0 !important;
}

[type="checkbox"] {
  border-radius: 0px !important;
  cursor: pointer;
  accent-color: var(--theme-body-bg-yellow-color);
  height: 14px !important;
  width: 14px !important;
  outline: none !important;
  border: 1px solid var(--theme-body-bg-yellow-dark-color) !important;
  font-size: 20px;
  transform: scale(1.3);
}

.spinner-status-box {
  width: 18px;
  height: 18px;
  display: grid;
  place-items: center;
  position: relative;
}

:is([type="submit"], [type="button"]) .spinner-status-box > .spinner-border {
  width: calc(100% - 0.5px);
  height: calc(100% - 0.5px);
  border-width: 2px;
  border-right-color: transparent;
  position: absolute;
  top: 0.5px;
  left: 0.5px;
}

:is([type="submit"], [type="button"]) .spinner-status-box > i {
  font-size: 18px;
}

@media (max-width: 991px) {
  .form-page-wrapper .bg-white {
    padding: 30px 15px;
  }
}

@media (max-width: 767px) {
  .form-box {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .form-page-wrapper {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 0 !important;
  }

  .form-page-wrapper-container + .footer-half-color::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--theme-body-bg-light-color);
    z-index: -1;
  }

  .form-page-wrapper-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* -------------------- Profile Page Wrapper -------------------- */

.profile-page-wrapper {
  background-color: var(--secondary-color);
  padding: var(--gap-25-50) 0;
}

.user-bio {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.user-bio p {
  font-weight: var(--fw-bold);
  line-height: 1;
  margin-top: 10px;
  margin-bottom: 0;
}

.user-bio small {
  font-weight: var(--fw-medium);
}

.user-bio .user-bio-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: var(--theme-body-bg-light-dark-color);
  overflow: hidden;
  position: relative;
}

.user-bio .user-bio-image .user-bio-icon {
  font-size: 24px;
  color: var(--theme-body-bg-yellow-color);
  background-color: rgb(26, 23, 27, 0.55);
  border: 1px solid var(--border-light-color);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  transform: scale(0) rotate(90deg);
  opacity: 0;
  transition: all 0.22s;
}

.user-bio .user-bio-image:is(.active, :hover) .user-bio-icon {
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

.user-bio .user-bio-button {
  margin-top: 15px;
}

.profile-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 25px;
}

.profile-grid .grid-item {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

@media (max-width: 991px) {
  .profile-grid {
    display: flex;
    flex-direction: column-reverse;
  }
}

/* -------------------- Contact Us -------------------- */

.contact-box {
  min-height: 200px;
  height: 100%;
  background-color: var(--theme-body-bg-light-color);
  padding: 75px 15px;
  border-radius: var(--border-radius-md);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.contact-box .contact-box-icon {
  position: absolute;
  top: 20px;
  left: 20px;
}

.contact-box .contact-box-icon .fa-icon {
  font-size: 27px;
  margin-left: 6px;
  margin-top: 5px;
}

.contact-box .contact-box-icon img {
  height: 45px;
}

.contact-box::after,
.contact-box::before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 100px;
  height: 100px;
  background-color: var(--theme-body-bg-yellow-color);
  border-radius: 50%;
  transition: all 0.22s ease;
  z-index: -2;
}

.contact-box:before {
  z-index: -1;
}

.contact-box:hover:before {
  background-color: var(--theme-body-bg-yellow-dark-color);
}

.contact-box:hover::after {
  width: 120% !important;
  height: 120% !important;
  border-radius: 15px;
}

.contact-box address {
  margin-bottom: 0;
}

.contact-box :is(a, address) {
  font-size: 20px;
  font-weight: var(--fw-medium);
}

.contact-box ul li a:hover {
  color: var(--black-text-color);
  text-decoration: underline;
}

.download-box {
  padding: 50px 15px !important;
}

.download-box::after,
.download-box::before {
  width: 0 !important;
  height: 0 !important;
}

.download-box .contact-box-icon {
  top: 12px;
  left: 12px;
}

.download-box .contact-box-icon img {
  height: 35px;
}

.download-box::after,
.download-box::before {
  width: 80px;
  height: 80px;
}

.download-box:hover .btn {
  background-color: var(--white-text-color) !important;
}

@media (min-width: 992px) {
  .contact-box-contnet {
    max-width: 80%;
    margin: 0 auto;
  }
}

#map {
  background-color: var(--theme-body-bg-light-color);
  padding-bottom: 35%;
  border-radius: var(--border-radius-md);
  position: relative;
  overflow: hidden;
}

#map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

#map .map-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  z-index: 1;
}

#map .map-loader .spinner-border {
  border-color: var(--theme-body-bg-yellow-dark-color);
  border-style: dotted;
  border-left-color: transparent;
}

@media (max-width: 991px) {
  #map {
    min-height: 400px;
  }

  .contact-box {
    padding: 60px 15px;
  }

  .contact-box :is(a, address) {
    font-size: 18px;
  }

  .contact-box .contact-box-icon img {
    height: 35px;
  }

  .contact-box::after,
  .contact-box::before {
    top: -8px;
    left: -8px;
    width: 80px;
    height: 80px;
  }
}

.contact-form-box {
  background-color: var(--theme-body-bg-light-color);
  padding: var(--padding-md);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

/* -------------------- gallrey slider -------------------- */
.gallery-box {
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  position: relative;
  padding-bottom: 58.3%;
}

.gallery-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* -------------------- Product Main Grid -------------------- */

.product-main-grid {
  display: grid;
  grid-template-columns: 330px 3fr;
  gap: 24px;
}

@media (max-width: 991px) {
  .product-main-grid {
    grid-template-columns: 1fr;
  }
}

/* -------------------- Fitler Box -------------------- */

.filter-box {
  background-color: var(--theme-body-bg-white-color);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--border-light-color);
}

.filter-box ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-box .filter-input li label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-box .filter-list li {
  background-color: var(--theme-body-bg-yellow-light-color);
  border: 1px solid var(--border-yellow-color);
  padding: 10px;
  border-radius: 5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: var(--fw-medium);
}

.filter-box .filter-list li i {
  background-color: var(--border-yellow-color);
  color: var(--black-text-color);
  font-size: 8px;
  padding: 5px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.filter-box .button-belt {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  box-shadow: 0px -2px 10px rgb(0, 0, 0, 0.1);
}

.filter-box .button-belt .btn {
  width: 100% !important;
  min-width: 100% !important;
  border-radius: 0 !important;
}

.filter-box .filter-box-body {
  padding: 30px 15px;
}

.filter-box .search-wrapper {
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  display: block !important;
}

.filter-box .search-wrapper input {
  width: 100%;
  min-width: 100%;
  height: 100%;
  border-radius: 5px !important;
}

.filter-box .search-wrapper .icon-group {
  background-color: var(--theme-body-bg-yellow-color) !important;
  width: 40px;
  right: 0;
  display: grid;
  place-items: center;
  font-size: 18px;
}

/* -------------------- Accordion 1 -------------------- */

.accordion-1 {
  padding-bottom: 0;
}

.accordion-1 .accordion-button::after {
  display: none !important;
}

.accordion-1 .accordion-header button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-1 .accordion-oc-icon {
  position: relative;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
}

.accordion-1 .accordion-oc-icon i {
  color: var(--yellow-text-color);
  font-size: 20px;
  font-weight: var(--fw-bold);
}

.accordion-1 .accordion-button.collapsed .accordion-oc-icon i.fa-minus {
  display: none;
}

.accordion-1 .accordion-button:not(.collapsed) .accordion-oc-icon i.fa-plus {
  display: none;
}

.accordion-1 .accordion-button {
  font-size: 14px !important;
  color: var(--black-text-color) !important;
  padding: 15px;
  background-color: transparent !important;
  box-shadow: unset !important;
  border-radius: 0 !important;
}

.accordion-1 .accordion-item {
  border-width: 1px 0;
  border-color: var(--border-light-color);
  border-radius: 0 !important;
}

.accordion-1 .accordion-body {
  padding: 15px 0;
  border-top: 2px solid var(--border-light-color);
  padding-bottom: 0;
}

.accordion-1 + .accordion-1 {
  padding-top: 0 !important;
}

#CMP_BLOCK_HOME .rounded-sm {
  border: 1px solid var(--border-light-color);
}

.checkout-tab-navs {
  border-bottom: 1px solid var(--border-light-color);
  margin-bottom: 15px;
}

.checkout-tab-navs .nav-item .nav-link {
  padding: 15px 60px 15px 0;
  border: 0;
  border-bottom: 4px solid transparent;
  background-color: transparent !important;
  color: var(--black-text-color) !important;
  font-weight: var(--fw-bold);
  border-radius: 0;
  font-size: 16px;
}

.checkout-tab-navs .nav-item .nav-link span {
  font-size: 12.5px;
  font-weight: var(--fw-bold);
  width: 20px;
  height: 20px;
  background-color: var(--theme-body-bg-light-dark-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 5px;
}

.checkout-tab-navs .nav-item .nav-link span.active,
.checkout-tab-navs .nav-item .nav-link.active span {
  background-color: var(--theme-body-bg-yellow-color);
}

.checkout-tab-navs .nav-item .nav-link.active {
  border-color: var(--theme-body-bg-yellow-dark-color);
}

@media (min-width: 992px) {
  .table-max-height {
    max-height: 287.5px !important;
  }
}

/* -------------------- Grid -------------------- */

.filter-top-belt-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 2fr)) 3fr;
  gap: 15px;
  padding-bottom: var(--gap-25);
  margin-bottom: var(--gap-25);
  align-items: flex-end;
  border: 2px solid var(--border-light-color);
  border-width: 0 0 3px 0;
}

.filter-top-belt-grid :is(.search-wrapper, .select-wrapper) {
  height: 46px;
}

.filter-top-belt-grid .search-wrapper .form-control:not(:focus) {
  max-width: 280px;
  height: 100%;
}

@media (max-width: 991px) {
  .filter-top-belt-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 0;
    padding-bottom: 0;
  }

  .filter-top-belt-grid > div {
    width: 100%;
  }

  .filter-top-belt-grid .search-wrapper .form-control:not(:focus) {
    max-width: 100%;
    height: 100%;
  }
}

/* -------------------- Filter Box -------------------- */

/* Plus-Minus Icon */

.accordion-plus-minus-icon i {
  color: var(--black-text-color);
  background-color: var(--theme-body-bg-light-dark-color);
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-button:not(.collapsed) .accordion-plus-minus-icon i {
  background-color: var(--theme-body-bg-yellow-color);
}

.accordion-button.collapsed .accordion-plus-minus-icon i.fa-minus {
  display: none;
}

.accordion-button:not(.collapsed) .accordion-plus-minus-icon i.fa-plus {
  display: none;
}

@media (max-width: 991px) {
  .reverse-on-mobile {
    display: flex;
    flex-direction: column-reverse;
  }

  .reverse-on-mobile .accordion-header {
    border-bottom: 2px solid var(--border-light-color);
    margin-bottom: var(--gap-25);
  }
}

/* Sidebar Tree Styles */
.sidebar-tree {
  background-color: var(--theme-body-bg-light-color);
  border-radius: 5px;
  border: 1px solid var(--border-light-color);
  padding: 0 25px 25px;
  min-height: 350px;
  max-height: 550px;
  overflow-y: auto;
  position: sticky;
  top: var(--header-height);
}

.sidebar-tree h5 {
  border-bottom: 2px solid var(--border-light-color);
  padding-top: 25px;
  padding-bottom: 12px;
  margin-bottom: 15px;
  background-color: var(--theme-body-bg-light-color);
  position: sticky;
  top: 0;
  z-index: 9;
}

.sidebar-tree .accordion-button {
  color: var(--black-text-color) !important;
  font-size: 15px;
  padding: 0 !important;
  display: flex;
  gap: 8px;
  align-items: center;
  box-shadow: unset !important;
  border: 0 !important;
  background-color: transparent !important;
}

.sidebar-tree .accordion-button::after {
  display: none !important;
}

.sidebar-tree .accordion-item {
  border: 0 !important;
  background-color: transparent;
  padding-bottom: 12px;
  position: relative;
}

.sidebar-tree .accordion-item::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 11px;
  bottom: 0;
  border-left: 1px solid #d8d8d8;
  height: calc(100% - 2px);
  transform: translateX(-50%);
}

.sidebar-tree .accordion-item:last-child {
  padding-bottom: 0;
}

.sidebar-tree .accordion-body {
  padding: 12px 0 0 30px;
}

.sidebar-tree .accordion-body ul {
  padding: 0 0 0 20px;
  margin: 0;
  list-style-type: disclosure-closed;
}

.sidebar-tree .accordion-body ul li::marker {
  color: rgb(26 23 27 / 30%);
}

.sidebar-tree .accordion-body ul li {
  font-size: 14px;
  cursor: pointer;
}

.sidebar-tree .accordion-body ul li:hover {
  color: var(--yellow-text-color);
}

.sidebar-tree .accordion-body ul li:not(:last-child) {
  margin-bottom: 5px;
}

.remove-style .accordion-button {
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: unset !important;
  display: block;
}

.remove-style .accordion-button::after {
  display: none !important;
}

.remove-style .accordion-item {
  border: 0 !important;
}

.remove-style .accordion-body {
  padding: 0 !important;
}

.remove-style .accordion-body {
  padding: 0 !important;
}

.remove-style .accordion-plus-minus-icon i {
  background-color: transparent !important;
}

.variation-modal .modal-body {
  padding: 30px;
}

.variation-modal :is(.modal-header, .modal-footer) {
  padding: 15px 30px;
}

.variation-modal :is(th, td) {
  border: 1px solid var(--border-light-color) !important;
  padding: 10px 15px;
}

@media (max-width: 991px) {
  .variation-modal .modal-body {
    padding: 30px 15px;
  }
}

/* accordion for cart item */
.cart-item-accordion {
  max-width: 350px;
  min-width: 250px;
  font-size: 12px;
}

.cart-item-accordion .accordion-button {
  background-color: transparent !important;
  padding: 10px 1px !important;
}

.cart-item-accordion button:focus {
  box-shadow: unset !important;
}

.cart-item-accordion .accordion-button > * {
  font-weight: var(--fw-light) !important;
}

.cart-item-accordion .accordion-item {
  background-color: transparent !important;
  border-width: 0 0;
  border-color: var(--border-light-color);
}

.cart-item-accordion .accordion-body {
  background-color: transparent !important;
  padding: 0;
}

.cart-item-accordion h4 {
  font-size: 16px;
  font-weight: var(--fw-light);
  border-bottom: 3px solid var(--border-light-color) !important;
  margin: 1rem 0;
  padding-bottom: 10px;
}

.cart-item-accordion .table-responsive {
  background-color: var(--theme-body-bg-white-color);
  border: 1px solid var(--border-light-color);
}

.cart-item-accordion table :is(th, td) {
  font-size: 14px !important;
}

.cart-item-accordion table tbody tr:nth-child(even) > * {
  background-color: var(--theme-body-bg-white-color) !important;
}

/* list */

.variant-option-list {
  margin: 10px 5px;
  padding: 0;
  list-style-type: none;
  font-size: 12px;
  font-weight: var(--fw-medium);
  max-width: 250px;
}

.variant-option-list li {
  background-color: var(--theme-body-bg-white-color);
  border: 1px solid var(--border-light-color);
  padding: 8px 12px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.variant-option-list span {
  text-transform: uppercase;
}

.variant-option-list b {
  font-weight: var(--fw-bold);
}

.style-none.accordion .accordion-body,
.style-none.accordion .accordion-item {
  background-color: transparent !important;
  padding: 0;
  border: 0;
}

/* faqs */
/* FAQ Tab Styles */
.faqs-tab .nav {
  display: flex;
  flex-direction: row;
  gap: 30px;
  position: relative;
}

.faqs-tab .nav::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 35px;
  background-color: var(--theme-body-bg-yellow-color);
  opacity: 0.2;
  transition: all 0.22s linear;
}

.faqs-tab .nav .nav-item .nav-link {
  color: var(--black-text-color);
  font-size: 16px;
  font-weight: var(--fw-bold);
  padding: 0 15px 10px;
  position: relative;
  box-shadow: unset !important;
}

.faqs-tab .nav .nav-item .nav-link::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0%;
  height: 4px;
  border-radius: 35px;
  background-color: var(--theme-body-bg-yellow-color);
  transition: all 0.22s linear;
}

.faqs-tab .nav .nav-item .nav-link.active::after {
  width: 100%;
}

.faqs-tab .tab-body {
  padding: var(--gap-25-50) 0 0;
}

/* FAQ Accordion Styles */
.faq-accordion .accordion-item {
  margin-bottom: 1rem;
  border: 2px solid rgba(221, 221, 221, 0.1);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  transition: all 0.08s ease-in-out;
}

.faq-accordion .accordion-item:hover {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
}

.faq-accordion .accordion-header .accordion-button {
  color: var(--black-text-color);
  font-size: 18px;
  font-weight: var(--fw-light) !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: unset;
  background-color: transparent;
}

.faq-accordion .accordion-header .accordion-button .accordion-icon {
  font-size: 12px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  color: var(--black-text-color);
  border: 2px solid var(--border-yellow-color);
  padding: 5px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: all 0.22s linear;
}

.faq-accordion
  .accordion-header
  .accordion-button:not(.collapsed)
  .accordion-icon {
  color: var(--black-text-color);
  background-color: var(--theme-body-bg-yellow-color);
  transform: rotate(-180deg);
}

.faq-accordion .accordion-header .accordion-button::after {
  display: none;
}

.faq-accordion .accordion-header {
  padding: 20px 30px;
}

.faq-accordion .accordion-body {
  font-size: 1rem;
  border-top: 1px solid var(--border-light-color);
  padding: 30px;
}

/* Mobile Dropdown Styles */
.mobile-tab-dropdown .dropdown-toggle {
  font-size: 14px;
  font-weight: var(--fw-bold);
  border: 2px solid var(--border-light-color) !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 10px 15px !important;
  text-transform: unset !important;
}

.mobile-tab-dropdown .dropdown-menu {
  background-color: var(--theme-body-bg-light-color);
  margin-top: -2px !important;
  width: 100%;
  border-radius: 5px;
}

.mobile-tab-dropdown .dropdown-menu .nav-link {
  border-bottom: 1px solid var(--border-light-color);
  padding: 10px 15px;
  font-size: 14px;
  font-weight: var(--fw-medium);
  box-sizing: unset;
}

.mobile-tab-dropdown .dropdown-menu .nav-link:last-child {
  border-bottom: 0 !important;
}

/* Responsive Styles */
@media (min-width: 992px) {
  .mobile-tab-dropdown {
    display: none;
  }
}

@media (max-width: 991px) {
  .faq-accordion .accordion-header,
  .faq-accordion .accordion-body {
    padding: 15px;
  }
}

.payment_method_option {
  display: flex;
  flex-direction: column;
}

.payment_method_option > div {
  padding: 10px 15px 10px 28px;
  border-bottom: 1px solid var(--border-light-color);
}
.payment_method_option > div label {
  font-size: 16px;
  font-weight: 600;
  text-transform: unset;
}

.fa-circle-info {
  color: #939393;
}

.payment_method_option > div input {
  border-color: var(--border-dark-color);
}

.payment_method_option > div:last-child {
  border-bottom: 0;
}
