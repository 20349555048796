/* -------------------- Header -------------------- */

header {
  --header-y-gap: 25px;
  --header-height: 64.5px;
  background-color: var(--theme-body-bg-white-color);
  padding: var(--header-y-gap) var(--container-gap);
  position: sticky;
  top: 0;
  z-index: 1024;
  transition: all 0.22s ease;
}

header .header-container {
  display: grid;
  grid-template-columns: 1fr 450px;
  justify-content: space-between;
}

header .header-left {
  display: flex;
  align-items: center;
  gap: 40px;
}

header .header-logo img {
  width: 150px;
  transition: all 0.22s ease;
}

header .header-menu > ul {
  display: flex;
  column-gap: 30px;
}

header .header-menu > ul > .nav-item > a {
  color: var(--black-text-color);
  font-size: 14px;
  font-weight: var(--fw-medium);
  transition: all 0.22s ease;
  text-transform: uppercase;
}

header .header-menu > ul > .nav-item > a:is(.active, :hover) {
  color: var(--yellow-text-color) !important;
  /* font-weight: var(--fw-bold) !important; */
}

header .header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

header .header-right > * {
  cursor: pointer;
}

header .header-right .search-megamenu {
  width: 100%;
}

.search-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.search-wrapper .icon-group {
  color: var(--borderlight-color);
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  display: flex;
  align-items: center;
}

.search-wrapper .form-control {
  max-width: 250px;
  background-color: var(--theme-body-bg-light-color);
  border: 0 !important;
  color: var(--black-text-color) !important;
  padding: 10px 20px !important;
  border-radius: 35px !important;
  line-height: 1.1 !important;
  transition: all 0.22s linear;
}

.search-wrapper .form-control:focus {
  width: 100%;
  max-width: 100%;
  background-color: var(--theme-body-bg-light-dark-color);
  color: var(--black-text-color) !important;
  border: 0 !important;
  box-shadow: unset !important;
}

header.active {
  --header-y-gap: 15px;
  box-shadow: 0 3px 8px 0 rgba(26, 23, 27, 0.2);
}

.overlay {
  display: none;
}

@media (min-width: 992px) and (max-width: 1199px) {
  header .header-container {
    grid-template-columns: repeat(2, auto);
  }

  header .header-left {
    gap: 30px;
  }

  header .header-menu > ul {
    gap: 15px;
  }

  header .header-menu > ul > .nav-item > a {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  header {
    --header-y-gap: 15px;
    /* padding-right: calc(var(--header-height) + 20px); */
  }

  header .header-container {
    grid-template-columns: repeat(2, auto);
  }

  #menu {
    position: fixed;
    top: var(--header-height);
    right: -350px;
    bottom: 0;
    width: 250px;
    background-color: var(--theme-body-bg-white-color);
    box-shadow: 3px 10px 8px rgb(26, 23, 27, 0.85);
    z-index: 999;
    overflow-y: auto;
    transition: all 0.22s linear;
  }

  .overlay {
    display: block;
    position: fixed;
    top: var(--header-height);
    left: 0;
    right: 100%;
    bottom: 0;
    background-color: var(--theme-body-bg-black-color);
    opacity: 0.9;
    z-index: 992;
  }

  header.active-menu #menu,
  .overlay.active-menu {
    right: 0;
  }

  header .header-menu > ul {
    flex-direction: column;
  }

  header .header-menu > ul > .nav-item > :is(a, button) {
    color: var(--black-text-color) !important;
    padding: 15px 15px !important;
    display: block;
  }

  header .header-menu > ul > .nav-item:first-child {
    border-top: 1px solid var(--border-light-color) !important;
  }

  header .header-menu > ul > .nav-item {
    border-bottom: 1px solid var(--border-light-color) !important;
  }

  header .header-right {
    gap: 7px;
  }
}

@media (max-width: 767px) {
  /* header { */
    /* padding-right: calc(var(--header-height) + 15px); */
  /* } */

  header .header-right :is(.MuiBadge-root, .header-user-profile) > svg {
    transform: scale(0.95);
    transform-origin: center right;
  }
}

@media (min-width: 992px) {
  header .header-menu > ul > .nav-item {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 1400px) {
  header .header-logo img {
    width: 180px;
  }
}

/* -------------------- Dropdown menu -------------------- */
.dropdown-toggle {
  background-color: transparent !important;
  color: unset !important;
  padding: 0 !important;
  border: 0 !important;
  min-width: unset !important;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  text-transform: uppercase;
}

.dropdown-toggle > i {
  margin-top: 2px;
  display: inline-block;
  transition: all 0.22s ease;
}

.dropdown-toggle.show > i {
  transform: rotate(-180deg);
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  width: 200px;
  margin-top: 29px;
  background-color: var(--theme-body-bg-white-color);
  padding: 0;
  border-radius: 5px;
  border: 1px solid var(--border-light-color);
  animation-name: slideIn;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  transition-delay: 0.22s;
}

header.active .dropdown-menu {
  margin-top: 19px;
}

.dropdown-menu > li > a {
  font-size: 16px;
  font-weight: var(--fw-medium);
  padding: 10px 15px;
  display: block;
  border-bottom: 1px solid var(--border-light-color);
}

.dropdown-menu > li > a:is(:hover, .active) {
  color: var(--black-text-color);
  background-color: var(--theme-body-bg-yellow-color);
  border-color: var(--border-white-color);
}

.dropdown-menu li:last-child > a {
  border-bottom: 0;
}

@media (max-width: 991px) {
  .dropdown-toggle {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    text-align: left;
  }

  .dropdown-menu {
    width: 100% !important;
    margin-top: 0 !important;
    position: static !important;
    inset: unset !important;
    transform: unset !important;
    background-color: var(--theme-body-bg-light-color);
    border-radius: 0 !important;
    border-width: 1px 0 0 0 !important;
  }

  .dropdown-menu .btn {
    width: 100%;
    min-width: 100%;
  }
}

@keyframes slideIn {
  0% {
    clip-path: inset(0 0 100% 0);
  }

  100% {
    clip-path: inset(0 0 0 0);
  }

  0% {
    clip-path: inset(0 0 100% 0);
  }
}

/* -------------------- Dropdown Megamenu -------------------- */
.dropdown-menu.megamenu {
  width: 650px;
  overflow: hidden;
}

.dropdown-menu.megamenu .dropdown-heading {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 2px solid var(--theme-body-bg-yellow-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu.megamenu .dropdown-heading h2 {
  margin-bottom: 0;
}

.dropdown-menu.megamenu .product-dropdown {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.dropdown-menu.megamenu .product-dropdown-image {
  background-color: var(--theme-body-bg-yellow-color);
  height: 100%;
  min-height: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.dropdown-menu.megamenu .product-dropdown-content {
  padding: 20px;
}

.dropdown-menu.megamenu .product-dropdown-content .grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
}

.dropdown-menu.megamenu .dropdown-navbar > li > a {
  color: var(--yellow-text-color);
  font-size: 18px;
  font-weight: var(--fw-bold);
  margin-bottom: 10px;
  display: inline-block;
}

.dropdown-menu.megamenu .dropdown-navbar > li > ul > li > a {
  font-weight: var(--fw-medium);
  display: block;
  margin-bottom: 5px;
}

.dropdown-menu.megamenu .dropdown-navbar > li > ul > li:last-child > a {
  margin-bottom: 0;
}

.dropdown-menu.megamenu .dropdown-navbar li a i {
  font-size: 14px;
  display: inline-block;
  transition: all 0.22s ease;
  margin-right: 5px;
}

.dropdown-menu.megamenu .dropdown-navbar li a:hover {
  color: var(--yellow-text-color);
}

.dropdown-menu.megamenu .dropdown-navbar li a:hover i {
  padding-left: 5px;
}

@media (max-width: 991px) {
  .dropdown-menu.megamenu {
    width: 100%;
  }

  .dropdown-menu.megamenu .product-dropdown {
    display: block;
  }

  .dropdown-menu.megamenu :is(.product-dropdown-image, h2) {
    display: none !important;
  }

  .dropdown-menu.megamenu .product-dropdown-content .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .dropdown-menu.megamenu .product-dropdown-content {
    display: flex;
    flex-direction: column-reverse;
  }

  .dropdown-menu.megamenu .dropdown-heading {
    padding: 15px 0 0;
    margin: 25px 0 0;
    border-top: 2px solid var(--theme-body-bg-yellow-color);
    border-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* -------------------- MenuBar -------------------- */

.menubar-icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--theme-body-bg-yellow-color);
  width: var(--header-height);
  height: 100%;
  display: grid;
  place-items: center;
}

header.active-menu .menubar-icon {
  background-color: var(--theme-body-bg-yellow-dark-color);
}

.menubar {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.menubar span {
  display: block;
  width: 100%;
  height: 2.5px;
  background: var(--black-text-color);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

header.active-menu .menubar span:nth-child(1) {
  -webkit-transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 7);
  transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 7);
}

header.active-menu .menubar span:nth-child(2) {
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, -8);
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, -8);
}

@media (min-width: 992px) {
  header :is(.menubar, .menubar-icon) {
    display: none;
  }
}

/* -------------------- Search menu  -------------------- */

.search-megamenu {
  position: relative;
}

.search-megamenu .search-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: var(--header-y-gap);
  display: none;
}

.search-megamenu .search-dropdown-menu .product-dropdown-menu {
  background-color: var(--theme-body-bg-white-color);
  border: 1px solid var(--border-light-color);
  border-radius: 5px;
  transform: translateY(100%);
  transition-delay: 0.22s;
  transition: all 0.22s ease;
  padding: 16px;
  max-height: 336px;
  overflow-y: auto;
}

.search-megamenu.show .search-dropdown-menu {
  display: block !important;
}

.search-megamenu.show .search-dropdown-menu .product-dropdown-menu {
  transform: translateY(0);
}

.MuiPaper-root.MuiPaper-elevation {
  background-color: var(--theme-body-bg-white-color) !important;
}

.cart-dropdown .MuiMenu-list {
  padding: 0 !important;
}

.cart-dropdown .MuiMenu-list > li {
  padding: 0;
  margin: 0;
  width: 100%;
}

.cart-dropdown .MuiPaper-elevation {
  width: 500px !important;
}

@media (min-width: 992px) {
  .search-megamenu .search-dropdown-menu {
    width: 450px;
  }
}

@media (max-width: 991px) {
  .cart-dropdown .MuiList-root {
    width: 100% !important;
  }
}

.cart-item {
  max-width: 300px;
  min-width: 250px;
  display: flex;
  column-gap: 15px;
  padding: 0 !important;
  color: var(--black-text-color);
  cursor: pointer;
}

.cart-item .img,
.cart-img {
  width: 80px;
  min-width: 80px;
  min-height: 80px;
  height: 80px;
  padding: 5px;
  background-color: var(--theme-body-bg-white-color);
  border: 1px solid var(--border-light-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.cart-item .img img,
.cart-img img {
  width: auto;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

.cart-item .text {
  font-size: 14px;
}

.cart-item .text .title {
  font-size: 14px;
  font-weight: var(--fw-bold) !important;
  display: block;
  line-height: 1.2;
  margin-bottom: 5px;
}

.cart-item .text .title:hover {
  cursor: pointer;
  color: var(--yellow-text-color);
}

.cart-item .text p {
  margin-bottom: 0px;
}

.cart-table .table-hr {
  border-width: 0 !important;
}

.cart-table tbody > .remove-first:first-child {
  display: none;
}

.cart-table .hover-unset:hover td {
  background-color: var(--theme-body-bg-light-color) !important;
}

.cart-table .table-hr > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.cart-table .table-hr hr {
  border-top-width: 5px;
  border-color: var(--border-yellow-color);
  margin: 0;
}

.cart-table .variation-tr {
  border-width: 0 !important;
}

/* .cart-table .variation-tr *:first-child {
  padding-left: 0;
}

.cart-table .variation-tr *:last-child {
  padding-right: 0;
} */

.table-link {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;
}

.table-link b {
  color: var(--theme-body-bg-yellow-dark-color);
  font-weight: var(--fw-bold) !important;
}

.cart-dropdown table {
  width: 100%;
}

.cart-dropdown table th {
  color: var(--yellow-text-color) !important;
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px 12px;
}

.cart-dropdown table thead tr {
  border-width: 0 0 3px;
  border-color: var(--body-bg-yellow-dark-color);
}

.cart-dropdown .table > * > * {
  background-color: var(--body-bg-blue-light-color-2) !important;
  color: var(--white-text-color);
}

.cart-dropdown table tfoot tr {
  border-width: 3px 0 0;
  border-color: var(--body-bg-yellow-dark-color);
}

.cart-dropdown table td {
  font-size: 14.5px;
  padding: 10px 12px;
}

.cart-dropdown table tbody tr {
  transition: all 0.11s ease;
  cursor: pointer;
}

.cart-dropdown table tbody tr:hover {
  background-color: var(--body-bg-blue-light-color) !important;
}

.cart-dropdown table tr td:last-child,
.cart-dropdown table tr th:last-child {
  text-align: end !important;
  vertical-align: middle !important;
  padding-right: 15px;
}

.cart-dropdown table tr td:not(:first-child, :last-child),
.cart-dropdown table tr th:not(:first-child, :last-child) {
  text-align: center;
  vertical-align: middle;
}

.cart-dropdown table tfoot tr th:last-child {
  font-size: 16px;
}

.cart-bottom {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin: -1rem !important;
  margin-top: 15px !important;
}

.cart-bottom .btn {
  border-radius: 0 !important;
  border-width: 1px !important;
}

/* -------------------- Small Dropdown -------------------- */
.css-106c1u2-MuiBadge-badge,
.MuiBadge-badge {
  background-color: var(--theme-body-bg-yellow-color) !important;
  color: var(--black-text-color) !important;
}

#basic-menu .MuiPaper-root.MuiMenu-paper {
  min-width: 200px;
}

.MuiPaper-elevation {
  border-radius: 5px;
  padding: 0;
  margin-top: 29px !important;
}

.basic-menu-active .MuiPaper-elevation {
  margin-top: 19px !important;
}

.MuiMenu-list {
  margin: 0 !important;
  padding: 0 !important;
}

.MuiMenu-list > li a,
.MuiMenu-list > li span {
  font-size: 16px;
  font-weight: var(--fw-medium);
  display: block;
  transition: all 0.15s ease;
}

.MuiMenu-list > li {
  padding: 10px 15px !important;
  border-bottom: 1px solid var(--border-light-color) !important;
  transition: all 0.15s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.MuiMenu-list > li > i {
  font-size: 14px;
  width: 20px;
}

.MuiMenu-list > li:last-child {
  border-bottom: 0;
}

.MuiMenu-list > li:is(:hover, .active) {
  background-color: var(--theme-body-bg-yellow-color);
  color: var(--black-text-color);
  border-bottom-color: var(--border-white-color) !important;
}

.MuiMenu-list > li:hover > :is(a, span) {
  color: var(--black-text-color);
}

@media (max-width: 991px) {
  .MuiPaper-elevation {
    margin-top: 18px !important;
  }
}

/* -------------------- Header Cart Aside -------------------- */
#user-cart-aside {
  --user-cart-aside-width: 630px;
  --user-cart-aside-gap: 30px;
  --user-cart-aside-right-minus: -650px;
  --user-cart-footer-height: 47.19px;
}

.user-cart-aside {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: var(--user-cart-aside-right-minus);
  width: var(--user-cart-aside-width);
  height: 100%;
  background-color: var(--theme-body-bg-white-color);
  padding: var(--user-cart-aside-gap);
  z-index: 999;
  box-shadow: 3px 3px 8px rgba(255, 255, 255, 0.8);
  overflow-y: auto;
  will-change: transform, right;
  transition: all 0.3s ease;
}

.user-cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 0;
  z-index: 992;
  background-color: var(--theme-body-bg-black-color);
  opacity: 0.9;
}

#user-cart-aside.active :is(.user-cart-aside, .user-cart-overlay) {
  right: 0;
}

.user-cart-aside .user-cart-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--gap-15-25);
  margin-bottom: var(--gap-15-25);
  border-bottom: 2px solid var(--theme-body-bg-yellow-color);
}

.cart-table {
  width: 100%;
}
.cart-table p > b {
  font-weight: var(--fw-bold);
  font-size: 12px;
}

.cart-table tbody tr:not(:last-child) {
  border-bottom: 5px solid var(--theme-body-bg-white-color);
}

.cart-table tbody tr td {
  font-size: 16px;
  background-color: var(--theme-body-bg-light-color);
  padding: 15px;
  transition: all 0.22s ease;
}

.cart-table tbody tr:hover td {
  background-color: var(--theme-body-bg-light-color);
}

.cart-table :is(thead, tfoot) tr th {
  font-size: 14px;
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  padding: 0 15px 0 0;
  text-align: center;
}

.cart-table :is(thead, tfoot) tr th:last-child {
  padding: 0;
}

.cart-table :is(thead, tfoot) tr th :is(span, span.span-box) {
  background-color: var(--theme-body-bg-light-dark-color);
  color: var(--black-text-color);
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  display: block;
  white-space: nowrap;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.checkout-page .cart-table :is(thead, tfoot) tr th span {
  margin-top: 0 !important;
}

.cart-table tbody tr td:last-child {
  font-weight: var(--fw-medium);
}

.cart-table tfoot th {
  font-size: 16px !important;
}

.cart-table tfoot .cart-total span {
  background-color: var(--theme-body-bg-yellow-color) !important;
  color: var(--black-text-color);
  font-weight: var(--fw-bold);
}

.user-cart-footer {
  position: fixed;
  bottom: 0;
  right: var(--user-cart-aside-right-minus);
  width: var(--user-cart-aside-width);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  /* Adjusted transition time */
  z-index: 999;
}

#user-cart-aside.active .user-cart-footer {
  right: 0 !important;
}

.user-cart-footer-gap {
  display: block;
  height: var(--user-cart-footer-height);
}

.user-cart-footer .btn {
  border-radius: 0 !important;
}

@media (max-width: 991px) {
  #user-cart-aside {
    --user-cart-aside-gap: 15px;
    --user-cart-aside-width: 250px;
    --user-cart-aside-right-minus: -300px;
    --user-cart-footer-height: 94.38px;
  }

  .user-cart-aside {
    padding: 20px 15px;
  }

  .user-cart-footer {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .cart-table :is(thead, tfoot) tr th :is(span, span.span-box) {
    font-size: 12px;
  }
}

/* -------------------- header dropdown menu -------------------- */
.header-dropdown-menu {
  margin-bottom: 20px;
}

.header-dropdown-menu > li > a {
  color: var(--yellow-text-color);
  font-size: 16px;
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}

.header-dropdown-menu > li > ul > li > a {
  font-weight: var(--fw-medium);
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}

.header-dropdown-menu > li > ul > li > a > i {
  color: var(--yellow-text-color);
  font-size: 14px;
  margin-top: 2px;
  margin-left: -22.25px;
  transition: all 0.15s ease;
}

.header-dropdown-menu > li > ul > li > a:hover {
  color: var(--yellow-text-color);
}

.header-dropdown-menu > li > ul > li > a:hover i {
  margin-left: 0;
}

/* -------------------- Footer -------------------- */

.contact-us-belt {
  background-color: var(--theme-body-bg-yellow-color);
  border-radius: 15px;
  padding: var(--belt-gap);
  gap: 15px;
}

.footer-half-color::after {
  content: "";
  position: absolute;
  bottom: -1%;
  left: 0;
  width: 100%;
  height: 51%;
  background-color: var(--theme-body-bg-black-color);
  z-index: -1;
  transform: scaleX(3);
}

/* .bg-dark + .footer-half-color::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--theme-body-bg-black-color);
    z-index: -1;
} */

#CMP_BLOCK_ABOUTUS + .footer-half-color {
  margin-top: var(--gap-25-50);
}

footer {
  background-color: var(--theme-body-bg-black-color);
  color: var(--white-text-color);
  padding: 40px 0 20px;
}

footer address {
  margin-bottom: 0;
}

footer .footer-logo {
  width: 180px;
}

footer .grid {
  display: grid;
  gap: 40px;
  margin-bottom: var(--gap-25-50);
}

footer a {
  color: var(--white-text-color);
}

footer a:hover {
  color: var(--white-text-color);
  text-decoration: underline;
}

footer .footer-menu a:hover {
  color: var(--yellow-text-color);
  text-decoration: none;
}

footer h4 {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: var(--gap-15-25);
}

footer ul li:last-child h4 {
  margin-bottom: 0;
}

footer .footer-menu {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

footer .footer-payment-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 350px;
}

footer .footer-payment-icons li {
  background-color: var(--theme-body-bg-white-color);
  border-radius: 5px;
  padding: 10px 12px;
  display: grid;
  place-items: center;
  transition: all 0.22s ease;
  cursor: pointer;
}

footer .footer-payment-icons li.lg {
  padding: 5px 12px;
}

footer .footer-payment-icons li:hover {
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.4);
  transform: translate(-2px, -2px);
}

footer .footer-payment-icons li img {
  width: 50px;
  height: 24px;
  object-fit: contain;
  object-position: center;
  transition: all 0.22s ease;
}

footer .footer-payment-icons li.lg img {
  height: 35px;
}

footer .footer-contact-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

footer .footer-contact-info li {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

footer .footer-contact-info .phone > a {
  font-size: 2.3rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
}

footer .footer-contact-info .email a {
  color: var(--yellow-text-color);
  font-weight: var(--fw-bold);
}

footer .copyright {
  gap: 20px;
}

footer .footer-social-icons {
  display: flex;
  gap: 15px;
}

footer .footer-social-icons li a {
  color: var(--white-text-color);
  border: 2px solid var(--white-text-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  transition: all 0.22s ease;
}

footer .footer-social-icons li a:hover {
  background-color: var(--white-text-color);
  color: var(--yellow-text-color);
}

footer .footer-social-icons li.google-plus a:hover {
  background: #dd4b39 !important;
  border-color: #dd4b39 !important;
  color: #fff !important;
}

footer .footer-social-icons li.linkedin a:hover {
  background-color: #0072b1 !important;
  border-color: #0072b1 !important;
  color: #fff !important;
}

footer .footer-social-icons li.youtube a:hover {
  background-color: #cd201f !important;
  border-color: #cd201f !important;
  color: #fff !important;
}

@media (min-width: 768px) {
  footer .grid {
    grid-template-columns: repeat(2, auto);
  }
}

@media (min-width: 992px) {
  footer {
    padding: 60px 0 30px;
  }

  footer .grid {
    grid-template-columns: repeat(4, auto);
  }

  footer .footer-contact-info li {
    align-items: flex-end;
    text-align: right;
  }
}

@media (max-width: 767px) {
  footer .grid {
    row-gap: var(--gap-25);
  }

  footer .grid-item {
    border-bottom: 1px solid var(--border-white-color);
    padding-bottom: var(--gap-25);
  }

  footer .footer-col-2 h4 a {
    color: var(--yellow-text-color);
  }

  footer .footer-payment-icons {
    margin-top: 25px !important;
  }

  .order_1 {
    order: 1;
  }

  .order_2 {
    order: 2;
  }

  .order_3 {
    order: 3;
  }
}
