.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 11px !important;
    font-weight: var(--fw-bold) !important;
    color: var(--black-text-color);
    background-color: var(--theme-body-bg-yellow-color);
    border-radius: 50%;
    min-width: 30px !important;
    height: 30px !important;
    display: grid;
    place-items: center;
    z-index: 992;
}

.swiper-button-next:hover:after,
.swiper-button-prev:hover:after {
    background-color: var(--theme-body-bg-yellow-dark-color);
}

.swiper-scrollbar-horizontal {
    position: static !important;
    width: 100%;
    margin-top: 25px;
    background-color: var(--border-light-color) !important;
}

.swiper-scrollbar-horizontal .swiper-scrollbar-drag {
    background: var(--theme-body-bg-yellow-color);
}

.swiper-slide {
    height: auto !important;
}

#product-swiper {
    overflow: unset;
    cursor: -webkit-grab;
}

/* -------------------- Product category slider -------------------- */

.product-category-swiper {
    overflow: unset !important;
    cursor: -webkit-grab;
}

.product-category-swiper .swiper-slide {
    width: auto;
}

.product-category-swiper :is(.swiper-button-next, .swiper-button-prev) {
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    top: calc(180px / 2 - 5px) !important;
    z-index: 992;
}

.product-category-swiper .swiper-button-prev {
    left: 5px;
    transform: translateX(-50%);
}

.product-category-swiper .swiper-button-next {
    right: 27.2%;
}

@media(min-width: 576px) {
    .product-category-swiper {
        overflow: unset !important;
        cursor: -webkit-grab;
    }

    .product-category-swiper .swiper-slide {
        width: auto;
    }

    .product-category-swiper .swiper-button-prev {
        left: 0;
    }

    .product-category-swiper .swiper-button-next {
        right: 2.46%;
    }
}

@media(max-width: 575px) {
    .product-category-swiper .card-category {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .product-category-swiper .card-category .card-content {
        width: 100%;
    }
}

@media(min-width: 1400px) {
    .product-category-swiper .swiper-button-next {
        right: 3.46%;
        /* right: calc(3.46% + 17px); */
    }
}

@media(min-width: 1600px) and (max-width: 1800px) {
    .product-category-swiper .swiper-button-next {
        right: 4%;
    }
}

/* -------------------- history gallery slider -------------------- */

#gallery-swiper {
    overflow: unset !important;
}

#gallery-swiper .swiper-slide {
    transition: all 0.4s ease-in-out;
    transition-delay: 0.4s;
}

#gallery-swiper .swiper-slide:not(.swiper-slide-active) {
    transform: scale(0.92);
}

#gallery-swiper :is(.swiper-button-prev, .swiper-button-next) {
    transform: scale(1.4);
}

#gallery-swiper .swiper-button-prev {
    left: 13.8%;
}

#gallery-swiper .swiper-button-next {
    right: 13.8%;
}

@media(max-width: 991px) {
    #gallery-swiper :is(.swiper-button-prev, .swiper-button-next) {
        transform: scale(1.12);
    }

    #gallery-swiper .swiper-button-prev {
        left: 6.8%;
    }

    #gallery-swiper .swiper-button-next {
        right: 6.8%;
    }
}


@media(max-width: 767px) {
    #gallery-swiper .swiper-button-prev {
        left: 1%;
    }

    #gallery-swiper .swiper-button-next {
        right: 1%;
    }
}