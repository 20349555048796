@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap");

@font-face {
  font-family: "Arial Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Bold"), url("../font/ARIALBD.woff") format("woff");
}

@font-face {
  font-family: "DINCond-Regular";
  src: url("../font/DINCond-Regular-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --theme-body-bg-black-color: #1a171b;
  --theme-body-bg-light-color: #f6f6f6;
  --theme-body-bg-light-dark-color: #e5e4e2;
  --theme-body-bg-yellow-color: #ffcc00;
  --theme-body-bg-yellow-light-color: #fffae5;
  /*ffec9c */
  --theme-body-bg-yellow-dark-color: #fbb900;
  --theme-body-bg-green-color: #56ca00;
  --theme-body-bg-red-color: #ed5b57;
  --theme-body-bg-red-dark-color: #c50d08;
  --theme-body-bg-red-light-color: #fe8e86;
  --theme-body-bg-white-color: #ffffff;
  --theme-body-bg-light-color: #f6f6f6;
  --theme-body-bg-blue-color: #006bfa;

  --yellow-text-color: var(--theme-body-bg-yellow-color);
  --black-text-color: var(--theme-body-bg-black-color);
  --white-text-color: var(--theme-body-bg-white-color);
  --link-text-color: #0060df;

  --border-light-color: #dddddda1;
  --border-dark-color: rgba(26, 23, 27, 0.23);
  --border-white-color: rgb(255, 255, 255, 0.2);
  --border-yellow-color: #ffd560;

  --container-gap: 20px;
  --fw-medium: 400;
  --fw-bold: 600;

  --section-padding: 50px;
  --section-padding-md: 50px;

  --gap-25: 25px;
  --gap-10-15: 10px;
  --gap-15-25: 15px;
  --gap-25-50: 25px;
  --belt-gap: 25px 20px;

  --md-font: 16px;
  --fs-16: 16px;

  --padding-lg: 50px 20px;
  --padding-md: 50px 20px;

  --header-height: 87px;

  --border-radius-md: 15px;
  --border-radius-sm: 10px;

  --highlight-width: 45px;
}

@media (min-width: 992px) {
  :root {
    --container-gap: 50px;

    --gap-10-15: 15px;
    --gap-15-25: 25px;
    --gap-25-50: 50px;
    --padding-md: 50px 30px;
  }
}

@media (min-width: 1200px) {
  :root {
    --container-gap: 50px;
    --section-padding: 75px;
    --section-padding-md: 50px;

    --md-font: 18px;
    --belt-gap: 40px;
    --highlight-width: 70px;
  }
}

@media (min-width: 1400px) {
  :root {
    --section-padding: 75px;
  }
}

@media (min-width: 1600px) {
  :root {
    --container-gap: 100px;
    --section-padding: 100px;

    --belt-gap: 40px 60px;
    --padding-md: 60px;
  }
}

@media (min-width: 1800px) {
  :root {
    --container-gap: 150px;
    --section-padding: 125px;
  }
}

@media (min-width: 1920px) {
  :root {
    --container-width: 1620px;
  }
}

body {
  font: 300 18px/1.6 "Quicksand", sans-serif;
  color: var(--black-text-color);
  background-color: var(--theme-body-bg-white-color);
}

header ul,
footer ul,
.ul-none {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--black-text-color);
}

a:hover {
  color: var(--yellow-text-color);
}

a,
a:hover {
  text-decoration: none;
}

b {
  font-weight: var(--fw-bold);
}

p a {
  color: var(--yellow-text-color);
  font-weight: var(--fw-medium);
  text-decoration: underline;
}

.link {
  font-size: 16px;
  font-weight: var(--fw-medium);
}

.link:is(:hover, :focus) {
  color: var(--theme-body-bg-yellow-color);
}

.link:is(:hover, :focus) {
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Arial Bold", Arial, sans-serif;
  /* font-weight: var(--fw-bold) !important; */
  line-height: 1.3;
}

.display-5 {
  /* font-weight: 900 !important; */
  text-transform: uppercase;
}

:is(h1, h2, h3, h4, h5, h6, .display-5) span {
  font-family: "Arial Bold", Arial, sans-serif;
  color: var(--yellow-text-color);
}

.hero-banner-1 :is(h1, h2, h3, h4, h5, h6, .display-5) :not(span) {
  font-family: "DINCond-Regular", Arial, sans-serif;
}

:is(.p-last-0, section) p:last-child {
  margin-bottom: 0 !important;
}

button[disabled] {
  opacity: 0.5 !important;
  cursor: not-allowed;
  user-select: none;
}

@media (max-width: 1199px) {
  .display-5 {
    font-size: calc(1.2rem + 1.9vw) !important;
  }
}

/* -------------------- Color -------------------- */

.text-danger {
  color: var(--theme-body-bg-red-color) !important;
}

.text-highlight-color {
  color: var(--yellow-text-color);
}

.bg-dark {
  background-color: var(--theme-body-bg-black-color) !important;
  color: var(--white-text-color) !important;
}

.bg-light {
  background-color: var(--theme-body-bg-light-color) !important;
}

.bg-card-color-1 {
  background: #5a9942;
  background: linear-gradient(to right, #82b74b, #5a9942) !important;
}

.bg-card-color-2 {
  background-color: #fe7096;
  background-image: linelinear-gradient(to right, #ffbf96, #fe7096) !important;
}

.bg-card-color-3 {
  background: #ff9800;
  background: linear-gradient(to right, #ff9800, #ff5722) !important;
}

.bg-card-color-4 {
  background: #007bff;
  background: linear-gradient(to right, #007bff, #0056b3) !important;
}

hr {
  border-color: var(--border-light-color);
  opacity: 1;
}

.yellow-text {
  color: var(--yellow-text-color);
}

/* -------------------- Loader -------------------- */
.bg-loader {
  min-height: 380px;
  background-color: var(--theme-body-bg-light-color);
  border: 1px solid var(--border-light-color);
  padding: 50px var(--gap-15-25);
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* -------------------- Color -------------------- */
button:focus {
  outline: unset !important;
}

.btn:not(.dropdown-toggle) {
  font-size: 14px;
  font-weight: var(--fw-bold);
  padding: 12px 25px !important;
  border-radius: 35px !important;
  line-height: 1.2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 170px;
  text-align: center;
  border-width: 2px !important;
  transition: all 0.22s ease;
  text-transform: uppercase;
}

.btn.btn-dark {
  font-weight: var(--fw-medium) !important;
}

.btn.btn-sm {
  font-size: 14px;
  padding: 8px 25px !important;
  min-width: auto;
}

.btn.btn-xxl-sm {
  font-size: 11px;
  padding: 6px 15px !important;
  min-width: auto;
}

.btn:focus {
  box-shadow: unset !important;
}

.btn-primary:not(.dropdown-toggle) {
  background-color: var(--theme-body-bg-yellow-color) !important;
  border-color: var(--theme-body-bg-yellow-color) !important;
  color: var(--black-text-color) !important;
}

.btn-primary:not(.dropdown-toggle):is(:hover, :focus) {
  background-color: var(--theme-body-bg-yellow-dark-color) !important;
  color: var(--black-text-color) !important;
}

.btn-outline-primary {
  background-color: transparent !important;
  border-color: var(--theme-body-bg-yellow-color) !important;
  color: var(--theme-body-bg-yellow-color) !important;
}

.btn-outline-primary:is(:hover) {
  background-color: var(--theme-body-bg-yellow-dark-color) !important;
  border-color: var(--theme-body-bg-yellow-dark-color);
  color: var(--black-text-color) !important;
}

.btn-white {
  background-color: var(--theme-body-bg-white-color) !important;
  border-color: var(--theme-body-bg-white-color) !important;
  color: var(--black-text-color) !important;
}

.btn-white:is(:focus, :hover) {
  background-color: var(--theme-body-bg-light-color) !important;
  border-color: var(--theme-body-bg-light-color) !important;
  color: var(--black-text-color) !important;
}

.btn-success {
  background-color: #2ca01c !important;
  border-color: #2ca01c !important;
  color: var(--white-text-color) !important;
}

.btn-success:is(:focus, :hover) {
  background-color: var(--theme-body-bg-green-color) !important;
  border-color: var(--theme-body-bg-green-color) !important;
  color: var(--white-text-color) !important;
}

.btn-quickbook {
  background-color: #2ca01c !important;
  border-color: #2ca01c !important;
  color: var(--white-text-color) !important;
}

.btn-quickbook > img {
  width: 22px !important;
  height: 22px !important;
}

.btn-quickbook:is(:focus, :hover) {
  background-color: var(--theme-body-bg-green-color) !important;
  border-color: var(--theme-body-bg-green-color) !important;
  color: var(--white-text-color) !important;
}

.btn-outline-danger {
  background-color: transparent !important;
  border-color: var(--theme-body-bg-red-color) !important;
  color: var(--theme-body-bg-red-color) !important;
}

.btn-outline-danger:is(:hover) {
  background-color: var(--theme-body-bg-red-dark-color) !important;
  border-color: var(--theme-body-bg-red-dark-color);
  color: var(--white-text-color) !important;
}

.btn-danger {
  background-color: var(--theme-body-bg-red-color) !important;
  border-color: var(--theme-body-bg-red-dark-color) !important;
  color: var(--white-text-color) !important;
}

.btn-danger:is(:focus, :hover) {
  background-color: var(--theme-body-bg-red-dark-color) !important;
  border-color: var(--theme-body-bg-red-dark-color) !important;
  color: var(--white-text-color) !important;
}

.btn .spinner-border {
  width: 12.8px;
  height: 12.8px;
  border-width: 2px;
}

.btn.sm {
  width: auto;
  min-width: auto;
  font-size: 14px !important;
  padding: 8px 15px !important;
}

.btn-trash-icon {
  width: 35px;
  height: 35px;
  font-size: 16px;
  color: var(--white-text-color);
  background-color: var(--theme-body-bg-red-color);
  border: 0;
  border-radius: 4px;
  padding: 5px;
  display: grid;
  place-items: center;
  transition: all 0.15s ease-in-out;
  outline: none !important;
}

.btn-trash-icon:is(:hover, :focus) {
  transform: scale(0.95);
  box-shadow: 0 0 0 1px rgb(225, 20, 20, 0.2);
}

.btn-item-remove-icon {
  color: var(--white-text-color);
  width: 32px;
  height: 32px;
  background-color: var(--theme-body-bg-red-color);
  border: 0;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.btn-item-remove-icon:hover {
  color: var(--white-text-color);
  background-color: var(--theme-body-bg-red-dark-color);
}

.sm-icon {
  width: 40px;
  height: 40px;
  background-color: var(--theme-body-bg-yellow-color);
  border: 0 !important;
  color: var(--black-text-color);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.22s ease-in-out;
}

.sm-icon.sm {
  width: 30px;
  min-width: 30px;
  height: 30px;
  font-size: 12px;
}

.sm-icon:is(:hover, .active, :focus) {
  background-color: var(--theme-body-bg-yellow-dark-color);
  color: var(--black-text-color);
  border: 0 !important;
  outline: unset !important;
  box-shadow: 0 0 12px rgba(255, 204, 0, 0.8);
}

.sm-icon-red {
  background-color: var(--theme-body-bg-red-color);
  color: var(--white-text-color);
}

.sm-icon-red:is(:hover, .active) {
  background-color: var(--theme-body-bg-red-dark-color);
  color: var(--white-text-color);
}

.btn-bottom-arrow-sm {
  background-color: var(--theme-body-bg-yellow-color) !important;
  color: var(--black-text-color) !important;
  padding: 5px;
  border-radius: 3px;
  width: 25px;
  height: 25px;
}

.btn-icon {
  min-width: 38px;
  min-height: 38px;
  padding: 5px;
  font-size: 14px;
  box-shadow: unset !important;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
}

.btn-icon:hover {
  background-color: var(--theme-body-bg-light-dark-color);
}

@media (max-width: 991px) {
  .btn:not(.dropdown-toggle) {
    font-size: 12px;
  }
}

.alert {
  font-size: 16px;
  font-weight: var(--fw-bold);
}

/* -------------------- gap -------------------- */

.custom-container {
  padding: 0 var(--container-gap);
}

.section-padding {
  padding: var(--section-padding) 0;
}

.section-padding-md {
  padding: var(--section-padding-md) 0;
}

.section-padding-sm {
  padding: 25px 0;
}

.section-padding:not(.bg-dark) + .section-padding:not(.bg-dark) {
  padding-top: 0 !important;
}

.mb-15-25 {
  margin-bottom: var(--gap-15-25);
}

.mb-25-50 {
  margin-bottom: var(--gap-25-50);
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.py-25-50 {
  padding: var(--gap-25-50) 0;
}

.padding-md {
  padding: var(--padding-md);
}

/* @media (min-width: 1920px) {
  .custom-container {
    max-width: var(--container-width);
    padding: 0 var(--container-gap);
    margin: 0 auto;
  }
} */

/* -------------------- heading -------------------- */

:is(.section-title-1, .section-title-2) {
  margin-bottom: var(--gap-25-50);
}

:is(.section-title-1, .section-title-2) h2 {
  text-transform: capitalize;
  margin-bottom: 0;
}

:is(.section-title-1, .section-title-2) p {
  margin-top: var(--gap-10-15);
}

.fs-4 {
  font-weight: var(--fw-medium);
  line-height: 1.4;
}

/* -------------------- section -------------------- */

main {
  overflow: hidden;
}

/* -------------------- Grid -------------------- */

.grid {
  display: grid;
  gap: 24px;
}

.product-card-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
  row-gap: 24px;
}

.product-card-grid-2 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
}

@media (min-width: 768px) {
  .product-card-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .product-card-grid-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1200px) {
  .product-card-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1400px) {
  .product-card-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1600px) {
  .product-card-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .product-card-grid-2 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/* -------------------- Other  -------------------- */

.qty-button {
  color: var(--black-text-color);
  font-size: 16px;
  font-weight: var(--fw-bold);
  border-radius: 35px;
  background-color: var(--theme-body-bg-yellow-color);
  color: var(--black-text-color);
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 100px;
  min-height: 40px;
}

.qty-button:active {
  background-color: var(--theme-body-bg-yellow-dark-color);
}

.qty-button button {
  padding: 5px;
  border: 0;
  background-color: transparent;
  color: inherit;
  display: inline-block;
  transition: all 0.15s ease;
}

.qty-button-sm {
  font-size: 12px;
  min-width: 70px;
  min-height: 32px;
}

.qty-button button:active {
  transform: scale(0.8);
}

[aria-live="polite"] br {
  display: none;
}

.form-check-input:checked {
  background-color: var(--theme-body-bg-yellow-color);
  border-color: var(--theme-body-bg-yellow-dark-color);
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 152 0 / 25%);
}

@media (max-width: 991px) {
  .desktop-only {
    display: none !important;
  }
}

@media (min-width: 991px) {
  .mobile-only {
    display: none !important;
  }
}

/* -------------------- Rounded shape  -------------------- */

.rounded-lg {
  border-radius: 50% !important;
  overflow: hidden !important;
}

.border {
  border: 1px solid var(--border-light-color) !important;
}

.rounded-sm {
  border-radius: var(--border-radius-sm) !important;
  overflow: hidden;
}

.rounded-sm-left {
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm) !important;
  overflow: hidden;
}

.rounded-sm-right {
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0 !important;
  overflow: hidden;
}

:is(.rounded-sm, .rounded-sm-left, .rounded-sm-right) img {
  transition: all 0.22s ease-in-out;
}

:is(.rounded-sm, .rounded-sm-left, .rounded-sm-right):hover img {
  transform: scale(1.05);
}

.image-and-content-image-border {
  border: 1px solid var(--border-light-color);
}

.not-found-img {
  width: 110px;
}

@media (min-width: 992px) {
  .not-found-img {
    width: 150px;
  }
}

/* -------------------- hghlight  -------------------- */

.highlight-top::before {
  background-color: var(--theme-body-bg-yellow-color);
  content: "";
  display: block;
  height: 6px;
  margin-bottom: 20px;
  width: var(--highlight-width);
}

.highlight-bottom::after {
  background-color: var(--theme-body-bg-yellow-color);
  content: "";
  display: block;
  height: 6px;
  margin-top: 20px;
  width: var(--highlight-width);
}

.highlight-top.sm::before {
  height: 4px;
  width: 35px;
  margin-bottom: 15px;
}

.highlight-bottom.sm::after {
  height: 4px;
  width: 35px;
  margin-top: 15px;
}

.highlight-top.center::before,
.highlight-bottom.center::after {
  margin-left: auto;
  margin-right: auto;
}

/* -------------------- scrollbar  -------------------- */
.scrollbar-sm::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar-sm::-webkit-scrollbar-track {
  background: var(--border-light-color);
  border-radius: 35px;
}

.scrollbar-sm::-webkit-scrollbar-thumb {
  background: var(--theme-body-bg-yellow-color);
  border-radius: 35px;
}

.scrollbar-sm::-webkit-scrollbar-thumb:hover {
  background: var(--theme-body-bg-yellow-dark-color);
}

.scrollbar-gray::-webkit-scrollbar-thumb {
  background: rgba(26, 23, 27, 0.23);
}

/* -------------------- Pagination -------------------- */

.pagination-wrapper {
  margin-top: var(--gap-15-25) !important;
}

.css-bf9wz-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: var(--theme-body-bg-yellow-color) !important;
  color: var(--black-text-color) !important;
}

.css-bf9wz-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: var(--theme-body-bg-light-color) !important;
}

/* -------------------- status-tag -------------------- */
.status-tag {
  font-size: 13px;
  font-weight: var(--fw-medium);
  padding: 6px 12px 5px;
  border-radius: 35px;
  line-height: 1;
  background-color: #d3d3d4;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
}

.status-tag.tag-success {
  background-color: var(--theme-body-bg-green-color);
  color: var(--white-text-color);
}

.status-tag.tag-warning {
  background-color: var(--theme-body-bg-yellow-color);
  color: var(--white-text-color);
}

.status-tag.tag-danger {
  background-color: var(--theme-body-bg-red-color);
  color: var(--white-text-color);
}

.status-tag.tag-info {
  background-color: var(--theme-body-bg-blue-color);
  color: var(--white-text-color);
}

.status-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--theme-body-bg-light-dark-color);
  display: inline-block;
}

.dot-success {
  background-color: var(--theme-body-bg-green-color);
}

.dot-danger {
  background-color: var(--theme-body-bg-red-color);
}

/* -------------------- sort by filter - belt -------------------- */

.sort-by-grid {
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: flex-end;
  gap: 15px;
}

.bg-light :is(.select-wrapper, .form-control) {
  background-color: var(--theme-body-bg-white-color) !important;
  border-radius: 35px !important;
}

.form-control[disabled] {
  opacity: 0.45 !important;
  background-color: var(--theme-body-bg-light-dark-color) !important;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .sort-by-grid {
    width: 100%;
    grid-template-columns: 1fr;
  }
}

/* -------------------- page loader -------------------- */
/* .spinner-grow.sm {
    width: 12px;
    height: 12px;
} */

/* .page-loader-box {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
}

.page-loader-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid var(--border-light-color);
    border-radius: 50%;
}


.page-loader-box::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-right: 3px solid var(--theme-body-bg-yellow-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
} */

.page-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-out;
}

.page-loader-box img {
  width: 45px;
  transition: all 0.2s linear;
}

.page-loader-box2 img {
  width: 250px;
}

@keyframes jumpAnimation {
  0% {
    transform: translateY(100%);
  }

  40% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes jumpAnimation2 {
  0% {
    transform: translateY(0%);
  }

  40% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.page-loader-box2 img {
  opacity: 1;
  transform: translateY(0%);
  animation: jumpAnimation 0.8s ease-in-out forwards;
  transition: opacity 0.4s ease-out 3s;
}

.page-loader-wrapper.active .page-loader-box2 img {
  animation: jumpAnimation2 0.4s ease-in-out forwards;
}

.page-loader-wrapper.loading {
  opacity: 0;
}

/* @keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */

/* -------------------- sort by filter - belt -------------------- */

.Toastify__toast--success .Toastify--animate-icon {
  background-color: #62ba5c;
}

.Toastify__progress-bar--62BA5C {
  background: #62ba5c !important;
}

.Toastify__toast--error .Toastify--animate-icon {
  background-color: #eb5757;
}

.Toastify__progress-bar--error {
  background: #eb5757 !important;
}

.Toastify__toast--warning .Toastify--animate-icon {
  background-color: #e78326;
}

.Toastify__progress-bar--warning {
  background: #e78326 !important;
}

.Toastify__toast-body {
  color: inherit;
  font-size: 14px;
  line-height: 1;
  padding: 0 !important;
  margin: 0 !important;
}

.Toastify__toast-body > div:not(.Toastify__toast-icon) {
  padding: 10px 15px;
}

.Toastify__toast {
  width: 350px !important;
  padding: 0 !important;
  min-height: 55px !important;
  max-height: 55px !important;
}

.Toastify__progress-bar {
  height: 3px !important;
}

.Toastify__toast > button > svg {
  display: none;
}

.Toastify--animate-icon {
  font-size: 18px;
  color: var(--white-text-color) !important;
  background-color: var(--theme-body-bg-light-color);
  width: 55px;
  height: 55px;
  display: grid;
  place-items: center;
}

.Toastify--animate-icon .sm {
  font-size: 16px;
}

.Toastify--animate-icon svg {
  width: 40px;
  height: 40px;
}

@media (max-width: 991px) {
  .Toastify__toast {
    top: 10px !important;
    left: 10px !important;
    width: 300px !important;
    margin-bottom: 10px !important;
  }
}

[onclick] {
  cursor: pointer;
}

.modal-backdrop.show {
  opacity: 0.2 !important;
}
